/**
 * Color Palette Layout
 * DBS>Interactive
 */
 .color-palette {
    &__swatches {
        gap: $spacing;
    }
    
    &__swatch {
        border-radius: 1rem;
        height: calc(50vw - #{$spacing * 1.5});
        width: calc(50% - #{$spacing / 2});
    }

    .contain {        
        @include media ($screen-md) {
            &.three-up .color-palette__swatch {
                height: calc(33vw - #{$spacing * 4});
                max-height: 19rem;
                width: calc(33.33% - #{$spacing});
            }
    
            &.four-up .color-palette__swatch {
                height: calc(25vw - #{$spacing * 1.5});
                max-height: 14rem;
                width: calc(25% - #{$spacing});
            }
            
            &.five-up .color-palette__swatch {
                height: calc(20vw - #{$spacing * 1.4});
                max-height: 11rem;
                width: calc(20% - #{$spacing});
            }
        }
    }
}
