/**
 * Image Focus CTA Flex Layout
 * DBS Interactive
 */

.image-focus-cta {
    .contain {
        align-items: stretch;
        justify-content: space-between;
    }

    &__content {
        background: linear-gradient(0deg, $blueDark, $blue);
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: #{$spacing * 2};
    }
    
	&__content,
	&__image {
		position: relative;

		img {
            display: block;
            height: 100%;
            object-fit: cover;
        }
	}
    
    &__image {
        position: relative;
        
        &:before {
            background: linear-gradient(90deg, $blue 0%, transparent 93%);
            mix-blend-mode: multiply;
            content: '';
            height: 100%;
            left: 0;
            opacity: 0.4;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

	@include media($screen-sm) {
		&__content {
            width: 40%;
        }
        
		&__image {
			width: 60%;
            
            &:after {
                background: $gold;
                content: '';
                height: 100%;
                left: 0;
                mix-blend-mode: color;
                position: absolute;
                top: 0;
                width: #{$spacing / 2};
            }
		}
	}
}