/**
 * Flex Blocks Layout
 * DBS>Interactive
 */
.flex-blocks {

	.cell {
		padding-top: 1em;
		width: 100%;

		@include media ($screen-sm) {
			width: 50%;
		}

		@include media ($screen-md) {
			width: 33.33%;
		}
	}
}
