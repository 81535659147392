/**
 * DBS Slate Main Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will be loaded on pages that aren't the front page or blog.
 */
/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Fonts
 */
/**
 * Type style definitions
 */
.brand-strip {
  background-color: #020202;
  content-visibility: auto;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 3rem;
  padding-top: 3rem; }
  .brand-strip__heading, .brand-strip__subheading {
    max-width: 47rem;
    text-align: center; }
    .brand-strip__heading p, .brand-strip__subheading p {
      font-size: 1.125rem; }
      @media screen and (min-width: 48em) {
        .brand-strip__heading p, .brand-strip__subheading p {
          font-size: 1.25rem; } }
  .brand-strip__heading {
    margin: 0 auto 3rem; }
  .brand-strip__subheading {
    margin: 3rem auto 0; }
  .brand-strip__strip {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
    @media screen and (max-width: 61.999em) {
      .brand-strip__strip {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
  .brand-strip__image {
    padding: 1rem; }
    .brand-strip__image img {
      max-height: 50px;
      width: auto; }
    @media screen and (min-width: 48em) {
      .brand-strip__image img {
        max-height: 75px; } }
    @media screen and (min-width: 62em) {
      .brand-strip__image img {
        max-height: 100px; } }

/**
 * Color Palette Layout
 * DBS>Interactive
 */
.color-palette__swatches {
  gap: 1.5rem; }

.color-palette__swatch {
  border-radius: 1rem;
  height: calc(50vw - 2.25rem);
  width: calc(50% - 0.75rem); }

@media screen and (min-width: 62em) {
  .color-palette .contain.three-up .color-palette__swatch {
    height: calc(33vw - 6rem);
    max-height: 19rem;
    width: calc(33.33% - 1.5rem); }
  .color-palette .contain.four-up .color-palette__swatch {
    height: calc(25vw - 2.25rem);
    max-height: 14rem;
    width: calc(25% - 1.5rem); }
  .color-palette .contain.five-up .color-palette__swatch {
    height: calc(20vw - 2.1rem);
    max-height: 11rem;
    width: calc(20% - 1.5rem); } }

.contact-cta__wrapper {
  padding: 1.5rem;
  position: relative; }

@media screen and (min-width: 48em) {
  .contact-cta__wrapper {
    padding: 3rem; } }

@media screen and (min-width: 62em) {
  .contact-cta__wrapper {
    padding: 4.5rem;
    padding-right: 9rem; }
  .contact-cta__main {
    padding-right: 3rem; }
    .contact-cta__main h3 {
      margin-bottom: 0;
      max-width: 34rem; }
  .contact-cta .button.ghost {
    font-size: 1.125rem;
    padding: 1rem 3rem; } }

/**
 *	Styles for the sidebar layout
 *
 *	This stylesheet properties only for layout-content_with_sidebar
 *		blog page has it's own stylesheet for sidebar
 *	[-If you want to make it global you can do it so-]
 */
.content-with-sidebar .contain {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media screen and (min-width: 75em) {
    .content-with-sidebar .contain {
      -ms-flex-direction: row;
      flex-direction: row; } }

@media screen and (min-width: 75em) {
  .content-with-sidebar .content {
    width: 70%; } }

.content-with-sidebar .content > .layout:first-child {
  margin-top: 0rem; }

.content-with-sidebar .content .layout:last-child {
  margin-bottom: 0; }

.content-with-sidebar .content .layout .contain, .content-with-sidebar .content .layout.contain {
  padding-left: 0;
  padding-right: 0; }

.content-with-sidebar .content .half-and-half__left.bg-img {
  margin-left: 0;
  width: 50%; }

.content-with-sidebar .content .half-and-half__right.bg-img {
  margin-right: 0;
  width: 50%; }

@media screen and (min-width: 75em) {
  .content-with-sidebar .sidebar {
    padding-right: 3rem;
    width: 30%; } }

.content-with-sidebar.sidebar-right .sidebar {
  -ms-flex-order: 99;
  order: 99;
  padding-right: 0;
  padding-left: 3rem; }

/**
 * CTA w/ Background Flex Layout
 * DBS>Interactive
 */
.cta-with-background {
  position: relative; }
  .cta-with-background hr {
    border-top: 2px solid #BF8E40;
    width: 5rem; }
  .cta-with-background .content {
    padding-left: 7.5rem;
    position: relative;
    z-index: 10; }
  .cta-with-background .eyebrow {
    color: #BF8E40; }
  .cta-with-background .image-wrapper {
    height: 100%;
    position: absolute;
    top: 0; }
    .cta-with-background .image-wrapper img {
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: left;
      width: 100vw; }
    .cta-with-background .image-wrapper.gold-gradient::before {
      background: linear-gradient(72deg, rgba(4, 4, 4, 0) 4.11%, rgba(0, 0, 0, 0.51) 55.08%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-one,
    .cta-with-background .image-wrapper.gold-gradient .overlays-two {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-one::before {
      background: linear-gradient(0deg, #000 0%, #000 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: color;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-one::after {
      background: linear-gradient(108deg, rgba(126, 91, 45, 0.44) 10.87%, rgba(239, 214, 131, 0.44) 82.01%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: overlay;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-two::before {
      opacity: 0.76;
      background: linear-gradient(106deg, #7E5B2D 19.5%, #EFD683 87.63%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: overlay;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 4; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-two::after {
      background: linear-gradient(278deg, rgba(4, 4, 4, 0.04) 0%, #37260F 73.19%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5; }
  .cta-with-background .main-heading {
    font-family: Lato, Verdana, Arial, sans-serif;
    text-transform: uppercase; }
  @media screen and (min-width: 48em) {
    .cta-with-background.bg-dark.layout {
      padding-bottom: 6rem;
      padding-top: 6rem; }
    .cta-with-background.dots .content::before {
      background-image: url("/images/dots2.svg");
      background-repeat: no-repeat;
      content: '';
      height: 100%;
      left: -5rem;
      pointer-events: none;
      position: absolute;
      top: 65%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 6; }
    .cta-with-background .content.half {
      width: 75%; } }
  @media screen and (min-width: 62em) {
    .cta-with-background .content.half {
      width: 65%; } }
  @media screen and (min-width: 75em) {
    .cta-with-background .content.half {
      width: 50%; } }
  @media screen and (max-width: 47.999em) {
    .cta-with-background .content {
      padding: 0;
      padding-bottom: 30%; }
    .cta-with-background .image-wrapper img {
      object-fit: contain;
      object-position: bottom; }
    .cta-with-background .image-wrapper.gold-gradient .overlays-two::after {
      background: linear-gradient(0deg, rgba(4, 4, 4, 0.04) -15%, #37260f 25%); } }

.featured-news hr {
  border-top: 4px solid #BF8E40;
  width: 5rem; }

.featured-news .contain {
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  gap: 3rem; }

.featured-news__cta {
  background-color: #F8F7F8;
  padding: 1.5rem; }
  .featured-news__cta .button {
    background-color: #BF8E40 !important;
    border-color: #BF8E40 !important; }
    .featured-news__cta .button:active, .featured-news__cta .button:focus, .featured-news__cta .button:hover {
      background-color: #ffffff !important;
      color: #BF8E40 !important; }

.featured-news__date {
  width: 35%; }

.featured-news__post {
  display: -ms-flexbox;
  display: flex; }
  .featured-news__post:not(:last-child) {
    border-bottom: 1px solid #F8F7F8;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem; }

.featured-news__text {
  width: 65%; }
  .featured-news__text a {
    color: #BF8E40;
    text-decoration: none; }

@media screen and (min-width: 48em) {
  .featured-news .contain {
    display: -ms-flexbox;
    display: flex; }
  .featured-news__cta {
    width: calc(40% - 1.5rem); }
  .featured-news__posts {
    width: calc(60% - 1.5rem); } }

@media screen and (min-width: 62em) {
  .featured-news .contain {
    gap: 6rem; }
  .featured-news__cta {
    width: calc(35% - 3rem); }
  .featured-news__posts {
    width: calc(65% - 3rem); } }

@media screen and (max-width: 47.999em) {
  .featured-news .contain {
    -ms-flex-direction: column;
    flex-direction: column; } }

.featured-text-strip {
  background-color: #52446F;
  color: #ffffff;
  position: relative;
  z-index: 10; }
  .featured-text-strip::after {
    content: '';
    height: 100%;
    left: 35%;
    position: absolute;
    top: 0;
    width: 100%; }
  .featured-text-strip h1, .featured-text-strip h2, .featured-text-strip h3 {
    color: #ffffff;
    font-weight: 600; }
  .featured-text-strip__text {
    color: #C2D1D9;
    font-size: 1.125rem;
    position: relative; }
  @media screen and (min-width: 48em) {
    .featured-text-strip .contain {
      -ms-flex-align: stretch;
      align-items: stretch;
      display: -ms-flexbox;
      display: flex; }
    .featured-text-strip__featured-text {
      border: 1.25rem solid #BF8E40;
      border-left: 0;
      margin-bottom: -1.25rem;
      margin-top: -1.25rem;
      padding: 4.5rem 3rem;
      width: 35%; }
    .featured-text-strip__text {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      padding: 4.5rem;
      width: 65%; } }
  @media screen and (max-width: 47.999em) {
    .featured-text-strip .contain {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
      padding-bottom: 3rem;
      padding-top: 3rem; } }

/**
 * Flex Blocks Layout
 * DBS>Interactive
 */
.flex-blocks .cell {
  padding-top: 1em;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .flex-blocks .cell {
      width: 50%; } }
  @media screen and (min-width: 62em) {
    .flex-blocks .cell {
      width: 33.33%; } }

/**
 * Full Width Flex Layout
 * DBS>Interactive
 *
 * This layout takes up the full width of the browser and may have a background image.
 */
.home-hero {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  position: relative; }
  .home-hero__background-image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    .home-hero__background-image img {
      height: 100%;
      max-width: none;
      object-fit: cover;
      width: 100%; }
  .home-hero__brand {
    cursor: pointer; }
  .home-hero__intro {
    position: relative;
    width: 90%; }
  @media screen and (min-width: 48em) {
    .home-hero__intro {
      width: 48%; }
    .home-hero .contain {
      padding: 18rem 1.5rem 0; } }
  .home-hero.citadel-healthcare, .home-hero.pavilion-healthcare {
    background-color: #ffffff; }
    .home-hero.citadel-healthcare a, .home-hero.pavilion-healthcare a {
      color: #FCEABE;
      text-decoration: none; }
    .home-hero.citadel-healthcare::before, .home-hero.pavilion-healthcare::before {
      background: linear-gradient(0deg, #020202 0%, #27282A 100%);
      content: '';
      height: 60%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .home-hero.citadel-healthcare .contain, .home-hero.pavilion-healthcare .contain {
      max-width: 90rem;
      padding: 9rem 1.5rem 0 0; }
    .home-hero.citadel-healthcare .home-hero__background-image, .home-hero.pavilion-healthcare .home-hero__background-image {
      max-width: 67.5rem; }
    .home-hero.citadel-healthcare .home-hero__intro, .home-hero.pavilion-healthcare .home-hero__intro {
      background: linear-gradient(270deg, #403158 0%, #020202 100%);
      border-right: 1rem solid #BF8E40;
      padding: 1.5rem; }
    @media screen and (min-width: 48em) {
      .home-hero.citadel-healthcare .contain, .home-hero.pavilion-healthcare .contain {
        padding: 16.5rem 1.5rem 7.5rem 0; }
      .home-hero.citadel-healthcare .home-hero__background-image, .home-hero.pavilion-healthcare .home-hero__background-image {
        left: unset;
        right: 0;
        width: 75%; }
      .home-hero.citadel-healthcare .home-hero__intro, .home-hero.pavilion-healthcare .home-hero__intro {
        padding: 3rem 1.5rem 3rem; }
      .home-hero.citadel-healthcare.dots::after, .home-hero.pavilion-healthcare.dots::after {
        background-image: url("/images/dots2.svg");
        background-repeat: no-repeat;
        bottom: -9rem;
        content: '';
        height: 25rem;
        right: 0;
        position: absolute;
        width: 6.1rem;
        z-index: 6; } }
    @media screen and (min-width: 62em) {
      .home-hero.citadel-healthcare .home-hero__intro, .home-hero.pavilion-healthcare .home-hero__intro {
        padding: 3rem 4.5rem 3rem 7.5rem; } }
    @media screen and (min-width: 90rem) {
      .home-hero.citadel-healthcare .home-hero__background-image, .home-hero.pavilion-healthcare .home-hero__background-image {
        left: calc(50% - 22.5rem);
        right: unset; }
      .home-hero.citadel-healthcare.dots::after, .home-hero.pavilion-healthcare.dots::after {
        right: calc(50vw - 45rem); } }
    @media screen and (max-width: 47.999em) {
      .home-hero.citadel-healthcare .home-hero__background-image:before, .home-hero.pavilion-healthcare .home-hero__background-image:before {
        background: linear-gradient(0deg, rgba(39, 41, 43, 0) 0%, #0A0A0B 100%);
        content: '';
        height: 20%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
      .home-hero.citadel-healthcare .home-hero__background-image:after, .home-hero.pavilion-healthcare .home-hero__background-image:after {
        background: linear-gradient(180deg, rgba(39, 41, 43, 0.4) 0%, #27282A 100%);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
      .home-hero.citadel-healthcare .home-hero__intro, .home-hero.pavilion-healthcare .home-hero__intro {
        background: transparent;
        border: 0; } }
  .home-hero.omnia-hcg .contain {
    padding: 15rem 1.5rem 0;
    position: relative; }
    .home-hero.omnia-hcg .contain::after {
      background: linear-gradient(180deg, rgba(39, 41, 43, 0) 1.98%, #27282A 101.98%);
      content: '';
      height: 21rem;
      left: -500%;
      position: absolute;
      bottom: 0;
      width: 1000%; }
  .home-hero.omnia-hcg .home-hero__background-image img {
    transform: rotateY(-180deg); }
  .home-hero.omnia-hcg .home-hero__background-image::before {
    background: linear-gradient(0deg, rgba(39, 41, 43, 0) 0%, #020202 100%);
    content: '';
    height: 21rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; }
  .home-hero.omnia-hcg .home-hero__background-image::after {
    background: linear-gradient(180deg, rgba(39, 41, 43, 0) 1.98%, #27282A 101.98%);
    content: '';
    height: 21rem;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1; }
  .home-hero.omnia-hcg .home-hero__brand {
    -ms-flex-align: center;
    align-items: center;
    background-color: black;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1.5rem;
    width: calc(50% - 0.75rem); }
    .home-hero.omnia-hcg .home-hero__brand img {
      max-height: 8rem;
      object-fit: contain; }
  .home-hero.omnia-hcg .home-hero__brands {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.5rem;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 13.5rem;
    position: relative;
    width: 100%;
    z-index: 1; }
    .home-hero.omnia-hcg .home-hero__brands::after {
      background-image: url("/images/dots2.svg");
      background-repeat: no-repeat;
      content: '';
      height: 100%;
      right: -90%;
      position: absolute;
      top: -120%;
      transform: 0;
      width: 100%;
      z-index: 6; }
  .home-hero.omnia-hcg .home-hero__intro h2 {
    position: relative; }
  .home-hero.omnia-hcg .home-hero__intro:before {
    background: linear-gradient(54deg, rgba(16, 16, 16, 0) 16.96%, rgba(25, 26, 28, 0.66) 66.97%), linear-gradient(8deg, #EFD683 17.22%, #7E5B2D 37.05%);
    content: '';
    height: 10rem;
    left: calc(1rem + 3px);
    position: absolute;
    bottom: -60%;
    width: 2px; }
  .home-hero.omnia-hcg .home-hero__intro:after {
    background: url("/icons/triangle.svg") center no-repeat;
    bottom: calc(-60% - .9rem);
    content: '';
    height: .5rem;
    left: 1rem;
    position: absolute;
    width: .5rem; }
  @media screen and (min-width: 48em) {
    .home-hero.omnia-hcg .home-hero__brand {
      width: calc(30% - 1.125rem); }
    .home-hero.omnia-hcg .home-hero__brands {
      width: calc(100% - 4.5rem); }
      .home-hero.omnia-hcg .home-hero__brands::after {
        right: -109%;
        top: 40%;
        transform: translateY(-50%); } }
  @media screen and (min-width: 62em) {
    .home-hero.omnia-hcg .home-hero__brand:nth-child(2) a {
      padding: 1.7rem; } }

/**
 * Image Focus CTA Flex Layout
 * DBS Interactive
 */
.image-focus-cta .contain {
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.image-focus-cta__content {
  background: linear-gradient(0deg, #182F44, #164767);
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 3rem; }

.image-focus-cta__content, .image-focus-cta__image {
  position: relative; }
  .image-focus-cta__content img, .image-focus-cta__image img {
    display: block;
    height: 100%;
    object-fit: cover; }

.image-focus-cta__image {
  position: relative; }
  .image-focus-cta__image:before {
    background: linear-gradient(90deg, #164767 0%, transparent 93%);
    mix-blend-mode: multiply;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.4;
    position: absolute;
    top: 0;
    width: 100%; }

@media screen and (min-width: 48em) {
  .image-focus-cta__content {
    width: 40%; }
  .image-focus-cta__image {
    width: 60%; }
    .image-focus-cta__image:after {
      background: #BF8E40;
      content: '';
      height: 100%;
      left: 0;
      mix-blend-mode: color;
      position: absolute;
      top: 0;
      width: 0.75rem; } }

.location-finder {
  position: relative; }
  .location-finder #search-form .search-form__container input:-internal-autofill-selected {
    background-color: black; }
  .location-finder #search-form .search-form__form {
    width: auto; }
  .location-finder #search-form #search-input {
    border: 0;
    color: #27282A;
    margin: 0;
    padding: calc(.666rem);
    transition: border-radius .5s ease, width .5s ease; }
    .location-finder #search-form #search-input:-ms-input-placeholder {
      color: #505D68; }
    .location-finder #search-form #search-input::placeholder {
      color: #505D68; }
  .location-finder__content {
    display: -ms-flexbox;
    display: flex;
    gap: 4.5rem;
    position: relative;
    z-index: 10; }
  .location-finder__heading h2 {
    font-weight: 600; }
  .location-finder__heading .eyebrow {
    color: #BF8E40;
    font-weight: 400;
    padding-left: 2rem;
    position: relative;
    text-transform: capitalize; }
    .location-finder__heading .eyebrow:before {
      background: url("/icons/locations.svg") no-repeat;
      content: '';
      height: 1.25rem;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.25rem; }
  .location-finder__list {
    font-size: 1.125rem;
    margin-bottom: 3rem;
    padding-left: 0;
    position: relative;
    z-index: 10; }
    .location-finder__list li {
      list-style-type: none;
      padding-left: 2.5rem;
      position: relative; }
      .location-finder__list li a {
        font-weight: 400;
        text-decoration: none; }
      .location-finder__list li:before {
        background: url("/icons/list-marker-gold.svg") no-repeat;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.25rem; }
      .location-finder__list li:not(:last-child) {
        margin-bottom: 1.5rem; }
  .location-finder__lists {
    margin-bottom: 0;
    padding-left: 0; }
    .location-finder__lists > li {
      list-style-type: none;
      margin-bottom: 0.75rem; }
  .location-finder:not(.display-list) #search-form {
    margin: 0 auto;
    max-width: 16.5rem; }
  .location-finder:not(.display-list) .location-finder__content {
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0; }
  .location-finder:not(.display-list) .location-finder__heading {
    text-align: center;
    width: auto; }
  .location-finder:not(.display-list) .main-heading {
    color: #EFD683; }
  .location-finder .contain {
    position: relative;
    z-index: 5; }
  .location-finder .image-wrapper {
    height: 100%;
    position: absolute;
    top: 0; }
    .location-finder .image-wrapper img {
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: left;
      width: 100vw; }
    .location-finder .image-wrapper::after {
      background: linear-gradient(180deg, #212121 0%, transparent 30%, transparent 100%);
      mix-blend-mode: multiply;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
  @media screen and (min-width: 30em) {
    .location-finder__lists {
      columns: 2; } }
  @media screen and (min-width: 48em) {
    .location-finder__content {
      padding-left: 7.5rem; }
    .location-finder__heading {
      width: 40%; }
    .location-finder__lists {
      columns: 2;
      width: 60%; }
    .location-finder.bg-dark.layout {
      padding-bottom: 6rem;
      padding-top: 6rem; }
    .location-finder.dots .location-finder__content::before {
      background-image: url("/images/dots2.svg");
      background-repeat: no-repeat;
      bottom: -9.7rem;
      content: '';
      height: 100%;
      left: -5rem;
      position: absolute;
      width: 6.1rem;
      z-index: 6; } }
  @media screen and (min-width: 62em) {
    .location-finder__content {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; }
    .location-finder.dots .location-finder__content::before {
      bottom: -18.7rem; } }
  @media screen and (max-width: 47.999em) {
    .location-finder__content {
      -ms-flex-direction: column;
      flex-direction: column; } }

/**
 * List w/ Background Flex Layout
 * DBS>Interactive
 */
.location-list {
  position: relative; }
  .location-list hr {
    border-top: 2px solid #BF8E40;
    width: 5rem; }
  .location-list__content {
    padding-left: 7.5rem;
    position: relative;
    z-index: 10; }
    .location-list__content.right {
      float: none; }
      .location-list__content.right .location-list__heading a {
        margin-left: 0; }
      .location-list__content.right .main-heading {
        display: block; }
  .location-list__heading {
    margin-bottom: 1.5rem; }
    .location-list__heading a {
      margin-left: 3rem; }
    .location-list__heading .eyebrow {
      color: #BF8E40;
      padding-left: 2rem;
      position: relative;
      text-transform: capitalize; }
      .location-list__heading .eyebrow:before {
        background: url("/icons/locations.svg") no-repeat;
        content: '';
        height: 1.25rem;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.25rem; }
    .location-list__heading .main-heading {
      display: inline-block; }
  .location-list__list {
    font-size: 1.125rem;
    margin: 0;
    padding-left: 0;
    position: relative;
    z-index: 10; }
    .location-list__list li {
      list-style-type: none;
      padding-left: 2.5rem;
      position: relative; }
      .location-list__list li a {
        font-weight: 400;
        text-decoration: none; }
      .location-list__list li:before {
        background: url("/icons/list-marker-gold.svg") no-repeat;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.25rem; }
      .location-list__list li:not(:last-child) {
        margin-bottom: 1.5rem; }
  .location-list .image-wrapper {
    height: 100%;
    position: absolute;
    top: 0; }
    .location-list .image-wrapper img {
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: left;
      width: 100vw; }
    .location-list .image-wrapper.gold-gradient::before {
      background: linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
    .location-list .image-wrapper.gold-gradient::after {
      background: linear-gradient(0deg, #000 0%, #000 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: color;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
    .location-list .image-wrapper.gold-gradient .overlays-one,
    .location-list .image-wrapper.gold-gradient .overlays-two {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%; }
    .location-list .image-wrapper.gold-gradient .overlays-one::before {
      background: linear-gradient(95deg, rgba(126, 91, 45, 0.44) 10.94%, rgba(239, 214, 131, 0.44) 85.89%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: overlay;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2; }
    .location-list .image-wrapper.gold-gradient .overlays-one::after {
      opacity: 0.76;
      background: linear-gradient(99deg, #7E5B2D 20.58%, #EFD683 88.84%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: overlay;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3; }
    .location-list .image-wrapper.gold-gradient .overlays-two::before {
      background: linear-gradient(270deg, rgba(4, 4, 4, 0.04) 1.84%, #37260F 75.94%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 4; }
    .location-list .image-wrapper.gold-gradient .overlays-two::after {
      opacity: 0.71;
      background: linear-gradient(70deg, rgba(4, 4, 4, 0.04) 35.45%, #37260F 63.18%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5; }
    .location-list .image-wrapper.dark-gray-gradient::after {
      background: linear-gradient(180deg, #212121 0%, transparent 20%, transparent 100%);
      mix-blend-mode: multiply;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
    .location-list .image-wrapper.brown-filter::after {
      background: rgba(55, 39, 15, 0.8);
      mix-blend-mode: multiply;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
  @media screen and (min-width: 48em) {
    .location-list.bg-dark.layout {
      padding-bottom: 6rem;
      padding-top: 6rem; }
    .location-list.dots .location-list__content::before {
      background-image: url("/images/dots2.svg");
      background-repeat: no-repeat;
      bottom: -8.7rem;
      content: '';
      height: 100%;
      left: -5rem;
      position: absolute;
      width: 6.1rem;
      z-index: 6; }
    .location-list__content.right {
      display: -ms-flexbox;
      display: flex;
      float: none;
      gap: 3rem; }
      .location-list__content.right .location-list__heading {
        -ms-flex-order: 1;
        order: 1; }
        .location-list__content.right .location-list__heading a {
          margin-left: 0; }
      .location-list__content.right .location-list__list {
        columns: 2; }
    .location-list__list {
      columns: 2; } }
  @media screen and (min-width: 62em) {
    .location-list__list {
      columns: 3; } }
  @media screen and (max-width: 47.999em) {
    .location-list__content {
      padding: 0; }
    .location-list__heading {
      margin-bottom: 3rem; }
      .location-list__heading a {
        margin-left: 0; }
      .location-list__heading .main-heading {
        display: block; } }

/**
 * List w/ Background Flex Layout
 * DBS>Interactive
 */
.list-with-background {
  position: relative; }
  .list-with-background hr {
    border-top: 2px solid #BF8E40;
    width: 5rem; }
  .list-with-background__content {
    padding-left: 7.5rem;
    position: relative;
    z-index: 10; }
    .list-with-background__content.right {
      float: none; }
      .list-with-background__content.right .list-with-background__heading a {
        margin-left: 0; }
      .list-with-background__content.right .main-heading {
        display: block; }
  .list-with-background__heading {
    margin-bottom: 1.5rem; }
    .list-with-background__heading a {
      margin-left: 3rem; }
    .list-with-background__heading .eyebrow {
      color: #BF8E40;
      padding-left: 2rem;
      position: relative;
      text-transform: capitalize; }
      .list-with-background__heading .eyebrow:before {
        background: url("/icons/locations.svg") no-repeat;
        content: '';
        height: 1.25rem;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.25rem; }
    .list-with-background__heading .main-heading {
      display: inline-block; }
  .list-with-background__list {
    font-size: 1.125rem;
    margin: 0;
    padding-left: 0;
    position: relative;
    z-index: 10; }
    .list-with-background__list li {
      list-style-type: none;
      padding-left: 2.5rem;
      position: relative; }
      .list-with-background__list li a {
        font-weight: 400;
        text-decoration: none; }
      .list-with-background__list li:before {
        background: url("/icons/list-marker-gold.svg") no-repeat;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.25rem; }
      .list-with-background__list li:not(:last-child) {
        margin-bottom: 1.5rem; }
  .list-with-background .image-wrapper {
    height: 100%;
    position: absolute;
    top: 0; }
    .list-with-background .image-wrapper img {
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: left;
      width: 100vw; }
    .list-with-background .image-wrapper.gold-gradient::before {
      background: linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
    .list-with-background .image-wrapper.gold-gradient::after {
      background: linear-gradient(0deg, #000 0%, #000 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: color;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
    .list-with-background .image-wrapper.gold-gradient .overlays-one,
    .list-with-background .image-wrapper.gold-gradient .overlays-two {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%; }
    .list-with-background .image-wrapper.gold-gradient .overlays-one::before {
      background: linear-gradient(95deg, rgba(126, 91, 45, 0.44) 10.94%, rgba(239, 214, 131, 0.44) 85.89%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: overlay;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2; }
    .list-with-background .image-wrapper.gold-gradient .overlays-one::after {
      opacity: 0.76;
      background: linear-gradient(99deg, #7E5B2D 20.58%, #EFD683 88.84%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
      mix-blend-mode: overlay;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3; }
    .list-with-background .image-wrapper.gold-gradient .overlays-two::before {
      background: linear-gradient(270deg, rgba(4, 4, 4, 0.04) 1.84%, #37260F 75.94%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 4; }
    .list-with-background .image-wrapper.gold-gradient .overlays-two::after {
      opacity: 0.71;
      background: linear-gradient(70deg, rgba(4, 4, 4, 0.04) 35.45%, #37260F 63.18%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5; }
    .list-with-background .image-wrapper.dark-gray-gradient::after {
      background: linear-gradient(180deg, #212121 0%, transparent 20%, transparent 100%);
      mix-blend-mode: multiply;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
    .list-with-background .image-wrapper.brown-filter::after {
      background: rgba(55, 39, 15, 0.8);
      mix-blend-mode: multiply;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }
  @media screen and (min-width: 48em) {
    .list-with-background.bg-dark.layout {
      padding-bottom: 6rem;
      padding-top: 6rem; }
    .list-with-background.dots .list-with-background__content::before {
      background-image: url("/images/dots2.svg");
      background-repeat: no-repeat;
      bottom: -8.7rem;
      content: '';
      height: 100%;
      left: -5rem;
      position: absolute;
      width: 6.1rem;
      z-index: 6; }
    .list-with-background__content.right {
      display: -ms-flexbox;
      display: flex;
      float: none;
      gap: 3rem; }
      .list-with-background__content.right .list-with-background__heading {
        -ms-flex-order: 1;
        order: 1; }
        .list-with-background__content.right .list-with-background__heading a {
          margin-left: 0; }
      .list-with-background__content.right .list-with-background__list {
        columns: 2; }
    .list-with-background__list {
      columns: 2; } }
  @media screen and (min-width: 62em) {
    .list-with-background__list {
      columns: 3; } }
  @media screen and (max-width: 47.999em) {
    .list-with-background__content {
      padding: 0; }
    .list-with-background__heading {
      margin-bottom: 3rem; }
      .list-with-background__heading a {
        margin-left: 0; }
      .list-with-background__heading .main-heading {
        display: block; } }

/**
 * Page Header Flex Layout
 * DBS>Interactive
 */
.page-header {
  padding-bottom: 3rem;
  padding-top: 3rem;
  background-color: #27282A;
  margin-top: 0 !important;
  overflow: hidden;
  position: relative; }
  @media screen and (min-width: 48em) {
    .page-header {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; } }
  .page-header hr {
    border-top: 2px solid #BF8E40;
    margin: 1.5rem 0;
    width: 5rem; }
  .page-header__bg-image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
    .page-header__bg-image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .page-header__bg-image img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .page-header__bg-image:before {
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, #27292B 0%, #000 100%), #D9D9D9;
      content: '';
      left: 0;
      mix-blend-mode: color;
      position: absolute;
      top: 0;
      z-index: 1; }
    .page-header__bg-image:after {
      height: 100%;
      width: 100%;
      background: radial-gradient(82.01% 51.74% at 76.49% 31.22%, rgba(0, 0, 0, 0) 0%, #27292B 100%), #D9D9D9;
      content: '';
      left: 0;
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
      z-index: 2; }
    .page-header__bg-image .bg-overlay {
      height: 101%;
      position: relative;
      width: 100%; }
      .page-header__bg-image .bg-overlay:before {
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, #252729 0%, rgba(39, 40, 42, 0) 100%);
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        z-index: 1; }
      .page-header__bg-image .bg-overlay:after {
        content: none; }
  .page-header__content {
    position: relative;
    z-index: 2; }
    .page-header__content h1:last-child {
      margin-bottom: 0; }
  .page-header.citadel-healthcare, .page-header.pavilion-healthcare {
    background-color: #ffffff;
    padding-bottom: 0;
    padding-top: 0; }
    .page-header.citadel-healthcare .contain, .page-header.pavilion-healthcare .contain {
      -ms-flex-align: start;
      align-items: flex-start; }
    .page-header.citadel-healthcare .eyebrow, .page-header.pavilion-healthcare .eyebrow {
      color: #FCB315;
      font-weight: 600;
      position: relative;
      text-transform: capitalize; }
      .page-header.citadel-healthcare .eyebrow::before, .page-header.pavilion-healthcare .eyebrow::before {
        background-color: #FCB315;
        content: '';
        height: 1px;
        position: absolute;
        right: calc(100% + 0.75rem);
        top: 50%;
        transform: translateY(-50%);
        width: 1000%; }
    .page-header.citadel-healthcare .page-header__content, .page-header.pavilion-healthcare .page-header__content {
      padding-top: 4.5rem; }
      .page-header.citadel-healthcare .page-header__content::before, .page-header.pavilion-healthcare .page-header__content::before {
        background: linear-gradient(0deg, #020202 0%, #27282A 100%);
        content: '';
        height: calc(100% + 4.5rem);
        left: -500%;
        position: absolute;
        top: 0;
        width: 1000%; }
    .page-header.citadel-healthcare .page-header__heading, .page-header.pavilion-healthcare .page-header__heading {
      position: relative; }
      .page-header.citadel-healthcare .page-header__heading h1, .page-header.pavilion-healthcare .page-header__heading h1 {
        color: #ffffff; }
    .page-header.citadel-healthcare .page-header__image, .page-header.pavilion-healthcare .page-header__image {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
      position: relative;
      z-index: 2; }
      .page-header.citadel-healthcare .page-header__image img, .page-header.pavilion-healthcare .page-header__image img {
        height: 100%;
        object-fit: cover; }
    .page-header.citadel-healthcare .page-header__text, .page-header.pavilion-healthcare .page-header__text {
      padding-top: 7.5rem; }
  .page-header.omnia-hcg .page-header__heading h1 {
    color: #EFD683; }
  .page-header.omnia-hcg .page-header__text {
    margin-top: 3rem; }
  .page-header .contain {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 3rem; }
  @media screen and (min-width: 48em) {
    .page-header__content, .page-header__image {
      width: 50%; }
    .page-header.citadel-healthcare .page-header__content-wrapper, .page-header.pavilion-healthcare .page-header__content-wrapper {
      width: calc(50% - 3rem); }
      .page-header.citadel-healthcare .page-header__content-wrapper .page-header__content, .page-header.pavilion-healthcare .page-header__content-wrapper .page-header__content {
        width: auto; }
    .page-header.omnia-hcg.big-o .page-header__image {
      position: relative; }
      .page-header.omnia-hcg.big-o .page-header__image:before {
        background-image: url("/images/big-o.svg");
        background-size: cover;
        background-repeat: no-repeat;
        content: '';
        height: 118%;
        left: -3%;
        position: absolute;
        top: -10%;
        width: 103%;
        z-index: 2; }
    .page-header.omnia-hcg.dots .page-header__image {
      position: relative; }
      .page-header.omnia-hcg.dots .page-header__image:after {
        background-image: url("/images/dots1.svg");
        background-repeat: no-repeat;
        content: '';
        height: 100%;
        right: -75%;
        opacity: .3;
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: 2; }
    .page-header.omnia-hcg .page-header__text {
      margin-left: 1.5rem;
      padding-left: 3rem;
      position: relative; }
      .page-header.omnia-hcg .page-header__text:before {
        background: linear-gradient(54deg, rgba(16, 16, 16, 0) 16.96%, rgba(25, 26, 28, 0.66) 66.97%), linear-gradient(8deg, #EFD683 17.22%, #7E5B2D 37.05%);
        content: '';
        height: 100%;
        left: 3px;
        position: absolute;
        top: 0;
        width: 2px; }
      .page-header.omnia-hcg .page-header__text:after {
        background: url("/icons/triangle.svg") center no-repeat;
        bottom: -.9rem;
        content: '';
        height: .5rem;
        left: 0;
        position: absolute;
        width: .5rem; }
    .page-header .contain {
      -ms-flex-direction: row;
      flex-direction: row; } }
  @media screen and (max-width: 47.999em) {
    .page-header__content {
      -ms-flex-order: 1;
      order: 1; } }

/**
 * Slider ACF Layout Styles
 * DBS>Interactive
 */
.slider__cell {
  width: 100%; }

.slider__element {
  box-shadow: none !important; }

.slider .flickity-viewport {
  transition: height 0.25s; }

.slider .flickity-page-dots {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  bottom: 0; }

.slider button.dot {
  border-radius: 100%;
  width: .75em;
  height: .75em;
  padding: 0;
  margin: 0em .75em;
  opacity: 1;
  background: transparent;
  transform: scale(1);
  border: 0.1em solid #ffffff; }
  .slider button.dot:focus, .slider button.dot:hover, .slider button.dot.is-selected {
    background-color: #020202;
    border-color: #ffffff;
    transform: scale(1.5);
    transition: transform .25s linear; }

@media screen and (min-width: 48em) {
  .slider__cell {
    margin: 0 0.75rem;
    width: calc(50% - 1.5rem); } }

.testimonials__slides {
  box-shadow: none !important; }

.testimonials__wrapper {
  padding: 1.5rem;
  width: 100%; }

.testimonials__author {
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem; }

.testimonials__body {
  padding-left: 1.5rem;
  position: relative; }
  .testimonials__body::before {
    height: 1rem;
    width: 1rem;
    background: url("/web/icons/quote.svg") no-repeat center;
    background-size: contain;
    content: '';
    font-size: 2rem;
    position: absolute;
    top: .25rem;
    left: 0; }

.testimonials .flickity-button.previous {
  left: 0; }

.testimonials .flickity-button.next {
  right: 0; }

@media screen and (min-width: 48em) {
  .testimonials__wrapper {
    padding: 3rem; }
  .testimonials__body {
    padding-left: 2.5rem; }
    .testimonials__body:before {
      height: 1.5rem;
      width: 1.5rem; } }

.team-members__heading {
  margin-bottom: 3rem; }

.team-members__item {
  margin: 0 1.5rem 1.5rem;
  width: calc(50% - 3rem); }

.team-members__image {
  border-radius: 50%;
  height: 0;
  margin-bottom: 1.5rem;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative; }
  .team-members__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .team-members__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.team-members__wrapper {
  margin: 0 -1.5rem -1.5rem; }

@media screen and (min-width: 62em) {
  .team-members__item {
    margin: 0 1.5rem 1.5rem;
    width: calc(25% - 3rem); } }

/**
 * Three Columns Flex Layout
 * DBS>Interactive
 */
.layout.three-columns {
  background-color: #ffffff; }
  .layout.three-columns h1, .layout.three-columns h2, .layout.three-columns h3 {
    color: #BF8E40; }
  .layout.three-columns p {
    color: #676767; }
  .layout.three-columns .three-columns__left,
  .layout.three-columns .three-columns__middle,
  .layout.three-columns .three-columns__right {
    width: 100%; }
    @media screen and (min-width: 48em) {
      .layout.three-columns .three-columns__left,
      .layout.three-columns .three-columns__middle,
      .layout.three-columns .three-columns__right {
        width: 33.33%; } }
  .layout.three-columns .contain {
    gap: 1.5rem; }
  @media screen and (max-width: 47.999em) {
    .layout.three-columns .column .cell {
      padding-left: 0;
      padding-right: 0; } }
  @media screen and (min-width: 62em) {
    .layout.three-columns {
      /**
		 * This little bit of hackery makes the edge of background images and
		 * background colors line up with the container content when a contain
		 * class is on the half and half layout, but not when the
		 * 'contain-full-width' reset class is used.
		 */ }
      .layout.three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:first-of-type, .layout.three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:last-of-type {
        width: calc(33.3333% - 1.5rem); }
      .layout.three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:first-of-type {
        margin-left: 1.5rem; }
      .layout.three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:last-of-type {
        margin-right: 1.5rem; }
      .layout.three-columns .columns {
        margin: 0 -1.5rem; } }

/**
 * Two Columns Flex Layout
 * DBS Interactive
 */
.two-columns hr {
  border-top: 2px solid #BF8E40;
  margin: 1.5rem 0;
  width: 5rem; }

.two-columns__left, .two-columns__right {
  position: relative; }

.two-columns.left-framed.big-o-left .two-columns__left, .two-columns.big-o-left .two-columns__left {
  position: relative; }
  .two-columns.left-framed.big-o-left .two-columns__left h1, .two-columns.left-framed.big-o-left .two-columns__left h2, .two-columns.left-framed.big-o-left .two-columns__left h3, .two-columns.left-framed.big-o-left .two-columns__left h4, .two-columns.left-framed.big-o-left .two-columns__left h5, .two-columns.left-framed.big-o-left .two-columns__left h6, .two-columns.left-framed.big-o-left .two-columns__left hr, .two-columns.left-framed.big-o-left .two-columns__left p, .two-columns.big-o-left .two-columns__left h1, .two-columns.big-o-left .two-columns__left h2, .two-columns.big-o-left .two-columns__left h3, .two-columns.big-o-left .two-columns__left h4, .two-columns.big-o-left .two-columns__left h5, .two-columns.big-o-left .two-columns__left h6, .two-columns.big-o-left .two-columns__left hr, .two-columns.big-o-left .two-columns__left p {
    position: relative; }
  .two-columns.left-framed.big-o-left .two-columns__left:before, .two-columns.big-o-left .two-columns__left:before {
    background-position: center;
    background-image: url("/images/big-o.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
    height: 215%;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 0;
    transform: translate(-50%, -50%); }

.two-columns.left-framed.big-o-right .two-columns__right, .two-columns.big-o-right .two-columns__right {
  position: relative; }
  .two-columns.left-framed.big-o-right .two-columns__right h1, .two-columns.left-framed.big-o-right .two-columns__right h2, .two-columns.left-framed.big-o-right .two-columns__right h3, .two-columns.left-framed.big-o-right .two-columns__right h4, .two-columns.left-framed.big-o-right .two-columns__right h5, .two-columns.left-framed.big-o-right .two-columns__right h6, .two-columns.left-framed.big-o-right .two-columns__right hr, .two-columns.left-framed.big-o-right .two-columns__right p, .two-columns.big-o-right .two-columns__right h1, .two-columns.big-o-right .two-columns__right h2, .two-columns.big-o-right .two-columns__right h3, .two-columns.big-o-right .two-columns__right h4, .two-columns.big-o-right .two-columns__right h5, .two-columns.big-o-right .two-columns__right h6, .two-columns.big-o-right .two-columns__right hr, .two-columns.big-o-right .two-columns__right p {
    position: relative; }
  .two-columns.left-framed.big-o-right .two-columns__right:before, .two-columns.big-o-right .two-columns__right:before {
    background-position: center;
    background-image: url("/images/big-o.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
    height: 215%;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 0;
    transform: translate(-50%, -50%); }

.two-columns.left-framed .contain {
  gap: 3rem; }

.two-columns.left-framed .two-columns__left {
  margin: 0 1rem 4rem;
  position: relative; }
  .two-columns.left-framed .two-columns__left .container {
    background-color: white;
    color: #676767;
    padding: 1.5rem;
    position: relative; }
    .two-columns.left-framed .two-columns__left .container h1, .two-columns.left-framed .two-columns__left .container h2, .two-columns.left-framed .two-columns__left .container h3, .two-columns.left-framed .two-columns__left .container h4, .two-columns.left-framed .two-columns__left .container h5, .two-columns.left-framed .two-columns__left .container h6 {
      color: #676767;
      font-family: Lato, Verdana, Arial, sans-serif; }
  .two-columns.left-framed .two-columns__left::before {
    background: linear-gradient(180deg, #9C712B 0%, #FCB315 100%);
    content: '';
    height: 85%;
    position: absolute;
    left: -1rem;
    top: calc(15% + 1rem);
    width: calc(100% + 2rem); }

@media screen and (max-width: 47.999em) {
  .two-columns__left {
    margin-bottom: 1.5rem; } }

@media screen and (min-width: 48em) {
  .two-columns.left-framed .two-columns__left {
    margin: 0 3rem; }
  .two-columns__left, .two-columns__right {
    width: calc(50% - 3rem); }
    .two-columns__left.contain-img img, .two-columns__right.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.contain-img img, .two-columns__right.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-columns__left.cover img, .two-columns__right.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.cover img, .two-columns__right.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .two-columns__left.contain-img, .two-columns__left.cover, .two-columns__right.contain-img, .two-columns__right.cover {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch; } }

@media screen and (min-width: 62em) {
  .two-columns__left > img:only-child,
  .two-columns__right > img:only-child {
    height: 100%; } }

/**
 * Collapsible Layout
 * DBS>Interactive
 */
.collapsible {
  margin: 3rem auto;
  background-color: #F8F7F8;
  padding: 3rem 0; }
  @media screen and (min-width: 48em) {
    .collapsible {
      margin: 4.5rem auto; } }
  .collapsible hr {
    border-top: 2px solid #BF8E40;
    margin: 1.5rem 0;
    width: 5rem; }
  .collapsible .collapsible-row {
    overflow: hidden;
    border-top: 1px solid #ffffff; }
    .collapsible .collapsible-row:first-of-type {
      border: none; }
    .collapsible .collapsible-row__header {
      font-size: 1em;
      -ms-flex-align: center;
      align-items: center;
      background: #ffffff;
      border: 0;
      border-bottom: 1px solid #F8F7F8 !important;
      color: #27282A;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      font-weight: 600;
      margin-bottom: 0;
      padding: 1rem 4rem 1rem 2rem;
      position: relative;
      text-align: left;
      transition: .4s;
      width: 100%; }
      @media (min-width: 48em) {
        .collapsible .collapsible-row__header {
          font-size: 1.25em; } }
      .collapsible .collapsible-row__header img {
        margin-right: 1rem; }
      .collapsible .collapsible-row__header:hover, .collapsible .collapsible-row__header:focus {
        background: #FCEABE; }
      .collapsible .collapsible-row__header:after {
        background: url("/icons/circle-arrow.svg");
        content: '';
        display: block;
        height: 2rem;
        position: absolute;
        top: 50%;
        right: 0;
        transition: transform .65s;
        transform: translate(-100%, -50%);
        width: 1.9rem; }
      .collapsible .expanded .collapsible-row__header {
        background: #FCEABE; }
        .collapsible .expanded .collapsible-row__header:after {
          transform: translate(-100%, -50%) rotate(90deg); }
      .collapsible .collapsible-row__header.active:after {
        transform: translate(-100%, -50%) rotate(180deg); }
    .collapsible .collapsible-row__content {
      background-color: #ffffff;
      padding: 2rem; }
      @media screen and (min-width: 48em) {
        .collapsible .collapsible-row__content {
          padding: 2rem 2rem 3rem; } }
    .collapsible .collapsible-row__content--wrapper {
      height: 100%;
      max-height: 0px;
      overflow: hidden;
      transition: max-height .4s ease-in-out, visibility 0s linear .5s; }
      .collapsible .collapsible-row__content--wrapper.loaded {
        max-height: 0; }
      .collapsible .expanded .collapsible-row__content--wrapper {
        max-height: 15em;
        transition: max-height .4s ease-in-out, visibility 0s linear; }
  .collapsible .contain {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 0.375rem; }
  .collapsible__heading {
    margin-bottom: 3rem; }
  @media screen and (min-width: 48em) {
    .collapsible .collapsible-row {
      width: calc(50% - 0.1875rem); }
    .collapsible__heading {
      width: 65%; } }

/**
 * Image Grid (with sidebar)
 * DBS>Interactive
 *
 * ACF Special Classes styled by this stylesheet:
 *		.light-box (Light Box)
 *		.image-zoom (Image Zoom)
 *
 * Note: The .light-box class included later in this stylesheet
 * 		cooresponds with functionality included in lightbox/lightbox.js
 */
.image-gallery__wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media screen and (min-width: 62em) {
    .image-gallery__wrapper {
      -ms-flex-direction: row;
      flex-direction: row; } }

.image-gallery__content {
  padding: 1.5rem; }
  @media screen and (min-width: 62em) {
    .image-gallery__content {
      width: 50%;
      display: -ms-inline-flexbox;
      display: inline-flex; } }
  .image-gallery__content .inner {
    max-width: 35em;
    margin: 0 auto;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  @media screen and (min-width: 62em) {
    .image-gallery__content + .image-gallery__images {
      width: 50%; } }

.image-gallery__images {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%; }

.image-gallery__image {
  border: 0.1em solid #ffffff;
  box-shadow: 0 0;
  height: 0;
  padding: 0;
  padding-bottom: 66%;
  position: relative;
  overflow: hidden;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .image-gallery__image {
      width: 50%;
      padding-bottom: 35%; } }
  @media screen and (min-width: 62em) {
    .image-gallery__image {
      width: 33.33333%;
      padding-bottom: 25%; } }
  @media screen and (min-width: 62em) {
    .image-gallery__image {
      width: 25%;
      padding-bottom: 20%; } }
  .image-gallery__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .image-gallery__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.individual-lightbox {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999; }
  .individual-lightbox.opened-box {
    display: inline-block; }
  .individual-lightbox .backDrop {
    background: #000;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: .8; }
  .individual-lightbox .image-container {
    background: #ffffff;
    border: 0.75rem solid #ffffff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 60rem;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    .individual-lightbox .image-container img.largeImage {
      object-fit: contain; }
  .individual-lightbox .close-lightbox {
    background: url("../icons/close-symbol.svg") no-repeat center;
    background-size: 2rem;
    background-color: #ffffff;
    border: none;
    font-size: 2.25em;
    height: 2em;
    width: 2em;
    position: absolute;
    right: -.75rem;
    top: -.75rem; }
  .individual-lightbox .largeImage {
    max-height: 80vh;
    width: 100%; }
  .individual-lightbox .lightbox-caption {
    background: rgba(0, 0, 0, 0.9);
    color: #ffffff;
    padding: 1em;
    width: 100%; }
  .individual-lightbox .contain-size .lightbox-caption {
    display: none; }

.admin {
  opacity: .75;
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  transition: opacity .25s; }
  .admin.active, .admin:hover, .admin:focus {
    opacity: 1; }
  .admin.active .admin__links {
    opacity: 1;
    transition: opacity .25s 0s, visibility 0s .0s;
    visibility: visible; }
  .admin__toggle {
    appearance: none;
    background: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1rem -0.5rem rgba(2, 2, 2, 0.75);
    height: 4rem;
    position: relative;
    width: 4rem; }
    .admin__toggle img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 75%;
      left: 55%;
      width: 75%; }
      @supports (object-fit: contain) {
        .admin__toggle img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
  .admin__links {
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 1rem -0.5rem rgba(2, 2, 2, 0.75);
    opacity: 0;
    padding: 1rem 1.5rem;
    position: absolute;
    bottom: 4.5rem;
    right: 0;
    transition: opacity .25s 0s, visibility 0s .25s;
    visibility: hidden; }
    .admin__links ul {
      list-style: none;
      margin: 0;
      text-align: right;
      padding: 0; }
      .admin__links ul li {
        margin-bottom: .5rem; }
        .admin__links ul li:last-child {
          margin-bottom: 0; }
        .admin__links ul li a {
          display: block;
          text-decoration: none; }
          .admin__links ul li a:after {
            background: #020202;
            content: '';
            display: block;
            height: 1px;
            position: relative;
            top: 0;
            transform: scaleX(0);
            transform-origin: 0 100%;
            transition: transform .25s ease-in-out; }
          .admin__links ul li a:hover, .admin__links ul li a:focus {
            text-decoration: none; }
            .admin__links ul li a:hover:after, .admin__links ul li a:focus:after {
              transform: scaleX(1);
              transform-origin: 100% 0; }

/**
 * Mixin to create a multi-directional animation with opacity fade.
 *
 * @example: 
 * @include animate3D( fadeRight, -3rem, 0, 0, 0);
 * .slideRight {animation: fadeRight 1s ease 0s 1 both;}
 */
/**
 * Several basic examples are below, but they are being kept commented out
 * to keep unnecessary code from making its way into production. For new animations
 * that involve a "transform: translate", use "translate3d" since it will perform better 
 * because of hardware acceleration.
 */
/**
 * Slide left to right and fade in from invisible 
 */
/**
 * Slide right to left and fade in from invisible 
 */
/**
 * Slide up and fade in from invisible 
 */
/**
 * Slide down and fade in from invisible 
 */
/**
 * Fade in only
 */
/**
 * Bounce
 */
/**
 * Not enough? This site has a lot of great animations with the ability to copy the
 * source code. https://animista.net/play/
 */
.flex {
  display: -ms-flexbox;
  display: flex; }

.flex-col {
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-col-r {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-row-r {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.aic {
  -ms-flex-align: center;
  align-items: center; }

.aie {
  -ms-flex-align: end;
  align-items: flex-end; }

.asc {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center; }

.ase {
  -ms-flex-item-align: end;
  align-self: flex-end; }

.ass {
  -ms-flex-item-align: start;
  align-self: flex-start; }

.jcc {
  -ms-flex-pack: center;
  justify-content: center; }

.jce {
  -ms-flex-pack: end;
  justify-content: flex-end; }

.jcsa {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.jcsb {
  -ms-flex-pack: justify;
  justify-content: space-between; }

.jcse {
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly; }

.fb-25 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%; }

.fb-50 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%; }

.fb-75 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%; }

@media screen and (max-width: 47.999em) {
  .flex-sm {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-sm {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-sm {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-sm {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-sm {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .wrap-sm {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .aic-sm {
    -ms-flex-align: center;
    align-items: center; }
  .aie-sm {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-sm {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-sm {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-sm {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-sm {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-sm {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-sm {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-sm {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .fb-25-sm {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-sm {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-sm {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 48em) {
  .flex-tab {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-tab {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-tab {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-tab {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-tab {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-tab {
    -ms-flex-align: center;
    align-items: center; }
  .aie-tab {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-tab {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-tab {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-tab {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-tab {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-tab {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-tab {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-tab {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-tab {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-tab {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-tab {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-tab {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-tab {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-tab {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 62em) {
  .flex-md {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-md {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-md {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-md {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-md {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-md {
    -ms-flex-align: center;
    align-items: center; }
  .aie-md {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-md {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-md {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-md {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-md {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-md {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-md {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-md {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-md {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-md {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-md {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-md {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-md {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 75em) {
  .flex-lg {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-lg {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-lg {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-lg {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-lg {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-lg {
    -ms-flex-align: center;
    align-items: center; }
  .aie-lg {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-lg {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-lg {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-lg {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-lg {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-lg {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-lg {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-lg {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-lg {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-lg {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-lg {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-lg {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

/**
 * Form Styles
 * DBS>Interactive
 */
.form {
  /**
	* Field Widths/Wrapping
	*/ }
  .form ul {
    list-style: none;
    padding: 0; }
  .form label, .form legend {
    font-weight: bold; }
  .form__field {
    margin-bottom: 1em; }
  .form .error-text {
    border-bottom: 0.1em solid #875E1D;
    color: #875E1D;
    padding-bottom: 1em; }
  @media screen and (min-width: 48em) {
    .form {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -.75rem; }
      .form__field-wrapper {
        padding: 0 .75rem;
        width: 100%; }
        .form__field-wrapper.width-25 {
          width: 25%; }
        .form__field-wrapper.width-33 {
          width: 33.33%; }
        .form__field-wrapper.width-50 {
          width: 50%; }
        .form__field-wrapper.width-66 {
          width: 66.66%; }
        .form__field-wrapper.width-75 {
          width: 75%; }
      .form .button {
        margin-left: .75rem; } }

fieldset > label.gfield_label_before_complex,
fieldset legend.gfield_label_before_complex,
.hidden_label > label.gfield_label_before_complex,
.hidden_label legend.gfield_label_before_complex,
.hide-label > label.gfield_label_before_complex,
.hide-label legend.gfield_label_before_complex {
  display: none; }

fieldset > label, fieldset legend,
.hidden_label > label,
.hidden_label legend,
.hide-label > label,
.hide-label legend {
  height: 1px;
  width: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

/**
 * Input Field Styles
 */
textarea {
  min-height: 5em; }

input[type=radio] {
  margin-right: .5em; }

select {
  min-width: 25%;
  margin: 0; }

textarea,
input[type=text]:not([id=search-input]):not([id=blog-search-field]),
input[type=email],
input[type=date],
input[type=number],
input[type=time],
input[type=week],
input[type=month],
input[type=tel],
input[type=search],
input[type=url],
.gfield select {
  display: block;
  border: 1px solid #EFD683;
  max-width: 100%;
  padding: .666em;
  width: 100%; }

fieldset {
  border: none;
  margin-bottom: 3rem;
  padding: 0 !important; }

.freeform-form-container {
  font-family: unset !important; }
  .freeform-form-container button {
    background-color: #BF8E40;
    border-color: #BF8E40;
    border-radius: 0;
    padding: .75rem 2rem;
    font: unset;
    font-weight: 700; }
    .freeform-form-container button:focus {
      border-color: #875E1D; }
    .freeform-form-container button:hover {
      background-color: #875E1D;
      border-color: #875E1D; }
  .freeform-form-container .freeform-row {
    font: unset; }
  .freeform-form-container .freeform-row [class*="freeform-col-"].floating input, .freeform-form-container .freeform-row [class*="freeform-col-"].floating .StripeElement, .freeform-form-container .freeform-row [class*="freeform-col-"].floating textarea, .freeform-form-container .freeform-row [class*="freeform-col-"].floating select {
    font: unset; }
  .freeform-form-container .freeform-row [class*="freeform-col-"].floating input:placeholder-shown:not(:focus) + *, .freeform-form-container .freeform-row [class*="freeform-col-"].floating textarea:placeholder-shown:not(:focus) + *, .freeform-form-container .freeform-row [class*="freeform-col-"].floating select:placeholder-shown:not(:focus) + * {
    font: unset; }
  .freeform-form-container .freeform-row [class*="freeform-col-"].floating label {
    font: unset; }

/**
 * Validation and error message styling
 */
.error-text {
  color: red; }

/**
 * Animated Form Label
 *
 * Class must be added in the GForms admin
 */
.animate-label {
  position: relative; }
  .animate-label .ginput_complex > span {
    height: 100%;
    width: 100%;
    display: inline-block;
    position: relative; }
    .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label .ginput_complex > span.active label, .animate-label .ginput_complex > span.active legend {
      transform: translateY(-2.25rem) scale(0.75); }
  .animate-label .ginput_complex.gfield_error label {
    transform: translateY(-2.25rem) scale(0.75); }
  .animate-label label, .animate-label legend {
    background: white;
    padding: 0 .25rem;
    position: absolute;
    top: 50%;
    left: .5rem;
    transition: .2s ease-in-out;
    transform: translateY(-50%);
    transform-origin: left; }
  .animate-label.select-field select {
    font-weight: 700;
    padding: 1.5rem .75rem; }
  .animate-label.text-area label {
    top: .5rem;
    transform: none; }
  .animate-label.text-area textarea {
    max-height: 12rem;
    padding-top: 3rem; }
  .animate-label.text-area.gfield_error label, .animate-label.text-area.gfield_error legend {
    transform: none; }
  .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label.active label, .animate-label.active legend {
    transform: translateY(-2.25rem) scale(0.75); }

/**
 * Table Styles with Responsive Design
 * Based on a CodePen Example by James TW:
 * https://codepen.io/james-tw/pen/GoaXrz
 */
/* Variables */
/**
 * Default styles
 */
table {
  margin-bottom: 1em;
  max-width: 100%;
  width: 100%;
  /**
	 * Responsive desktop styles
	 */
  /**
	 * Mobile only styles
	 */ }
  table tbody {
    display: block; }
  table tr {
    border: 1px solid #e0e0e0;
    display: block;
    margin-bottom: 1rem;
    transition: background-color .25s ease; }
  table th,
  table td {
    border-top: 0;
    padding: 1rem;
    transition: background-color .25s ease;
    text-align: left;
    vertical-align: top; }
  table th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: #757575;
    font-weight: 400;
    vertical-align: bottom; }
  table td {
    display: block;
    text-align: right; }
  table tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
    table tfoot td {
      color: #757575;
      font-weight: 400; }
  @media screen and (min-width: 48em) {
    table tbody {
      display: table-row-group; }
    table tr {
      border: none;
      display: table-row; }
    table td {
      display: table-cell;
      text-align: left; } }
  @media screen and (max-width: 47.999em) {
    table thead,
    table tfoot {
      display: none; }
    table td[data-title]:before {
      color: #757575;
      content: attr(data-title);
      float: left;
      font-size: inherit;
      font-weight: 400; }
    table:not(._) td:first-child {
      background-color: #4D4D4D;
      border: none;
      color: #ffffff;
      font-weight: 500;
      text-align: left; }
      table:not(._) td:first-child::before {
        display: none; } }

/* Table classes -- Add manually to the table element within the CMS */
/**
 * Bordered table
 *
 * Add horizontal borders between columns.
 */
.table--bordered {
  border: 0; }
  .table--bordered th,
  .table--bordered td {
    border-bottom: 1px solid #e0e0e0; }
    @media screen and (min-width: 48em) {
      .table--bordered th,
      .table--bordered td {
        border: 1px solid #e0e0e0; } }
  .table--bordered thead th,
  .table--bordered thead td {
    border-bottom-width: 2px; }

/**
 * Zebra-striping
 * Default zebra-stripe styles (alternating gray and transparent backgrounds)
 */
.table--striped td:nth-child(odd) {
  background-color: #f5f5f5; }

/**
 * Hover effect styling
 */
@media screen and (min-width: 48em) {
  .table--hover tr:hover {
    cursor: pointer; }
    .table--hover tr:hover td,
    .table--hover tr:hover td:first-child {
      background-color: rgba(0, 0, 0, 0.05); } }

.find-location-header {
  margin-top: 0; }
  .find-location-header .breadcrumbs {
    margin-bottom: 0.75rem;
    margin-top: 0.75rem; }
  .find-location-header .d-flex {
    display: -ms-flexbox;
    display: flex;
    gap: 20px; }

.location-header__content {
  -ms-flex-preferred-size: 39.3%;
  flex-basis: 39.3%;
  padding-right: 4rem;
  padding-top: 5rem; }
  .location-header__content #location-form .location-form__container input:-internal-autofill-selected,
  .location-header__content #location-form .location-form__container select:-internal-autofill-selected {
    background-color: black; }
  .location-header__content #location-form .location-form__form {
    width: auto; }
  .location-header__content #location-form #filter-service,
  .location-header__content #location-form #pac-input {
    border-radius: 0;
    color: #27282A;
    font-size: 1rem;
    margin: 0;
    transition: border-radius .5s ease, width .5s ease; }
    .location-header__content #location-form #filter-service:-ms-input-placeholder,
    .location-header__content #location-form #pac-input:-ms-input-placeholder {
      color: #505D68; }
    .location-header__content #location-form #filter-service::placeholder,
    .location-header__content #location-form #pac-input::placeholder {
      color: #505D68; }
  .location-header__content .eyebrow {
    color: #BF8E40;
    padding-left: 2rem;
    position: relative;
    text-transform: capitalize; }
    .location-header__content .eyebrow:before {
      background: url("/icons/locations.svg") no-repeat;
      content: '';
      height: 1.25rem;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.25rem; }

.location-header__map {
  -ms-flex-preferred-size: 59%;
  flex-basis: 59%;
  height: 491px; }
  .location-header__map img {
    height: 100%; }

.location-header__input {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1.5rem; }
  .location-header__input input,
  .location-header__input select {
    border-radius: 20px;
    border: 1px solid #9C9C9C !important;
    color: #6B6B6B;
    font-size: 14px;
    font-weight: 400; }
  .location-header__input select {
    appearance: none;
    line-height: 1.15;
    outline-offset: -2px;
    padding: .666em;
    text-indent: 1px;
    text-overflow: '';
    width: 100%; }

.location-header__button {
  background-color: #63656A;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-left: -5px;
  text-decoration: none; }
  .location-header__button:hover {
    color: #ffffff;
    background-color: #2d2d2d; }

.location-header__bottom {
  margin-top: 2.5rem;
  display: -ms-flexbox;
  display: flex;
  gap: 20px; }

.location-header-bottom__content {
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  -ms-flex-align: center;
  align-items: center; }
  .location-header-bottom__content figure {
    width: 2rem;
    height: 2rem;
    margin: 0; }
  .location-header-bottom__content h4 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    color: #27282A;
    margin-bottom: 0;
    width: calc(100% - 45px); }

.location-lists {
  margin-top: 4.5rem; }
  .location-lists__card {
    display: -ms-flexbox;
    display: flex;
    gap: 1.5rem;
    border-top: 1px solid #D9D9D9;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 1.5rem; }
  .location-lists-card__left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    gap: 2.5rem; }
    .location-lists-card__left figure {
      margin: 0; }
    .location-lists-card__left h4 {
      color: #1A2E50;
      font-size: 28px;
      font-weight: 400;
      line-height: normal; }
  .location-lists-card__right {
    -ms-flex-preferred-size: 23.3%;
    flex-basis: 23.3%; }
    .location-lists-card__right p {
      margin-bottom: 1.5rem; }
      .location-lists-card__right p img {
        float: left;
        margin-right: 8px; }
      .location-lists-card__right p:first-child img {
        margin-left: -7px; }
      .location-lists-card__right p > span {
        vertical-align: middle; }
      .location-lists-card__right p > svg {
        vertical-align: middle; }
        .location-lists-card__right p > svg.location {
          width: 1.75rem; }
        .location-lists-card__right p > svg.phone {
          width: 1.5rem; }
    .location-lists-card__right .location-header__button {
      margin-left: 0;
      width: 80%;
      display: block;
      text-align: center;
      margin-top: 40px; }
  .location-lists-card-content h4 {
    font-weight: 700; }
  .location-lists-card-content__lists {
    margin-bottom: 25px; }
    .location-lists-card-content__lists li {
      font-family: Helvetica;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: #1A2E50;
      margin-bottom: 10px;
      position: relative;
      list-style: none; }
      .location-lists-card-content__lists li::before {
        content: "";
        position: absolute;
        left: -27px;
        top: 10px;
        width: 1rem;
        height: 3px;
        background: #4E77B9; }
      .location-lists-card-content__lists li:last-child {
        margin-bottom: 0; }
  @media screen and (min-width: 48em) {
    .location-lists__card {
      display: -ms-flexbox;
      display: flex; }
    .location-lists-card__left {
      width: 80%; }
      .location-lists-card__left img {
        max-width: 18rem; }
    .location-lists-card__right {
      width: 20%; } }
  @media screen and (max-width: 47.999em) {
    .location-lists-card__left {
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 1.5rem; } }

.help-layout {
  padding: 2.25rem 0 3.75rem; }

.help__content {
  text-align: center;
  padding: 0 3.125rem; }
  .help__content h4 {
    color: #505D68;
    text-align: center;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.375rem;
    text-transform: uppercase;
    margin-bottom: 1.4375rem; }
  .help__content p {
    color: #505D68;
    text-align: center;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 300;
    line-height: 2.1875rem;
    margin-bottom: 1.4375rem; }
  .help__content .location-header__button {
    margin-left: 0;
    display: inline-block;
    padding: 0.625rem 2.8125rem; }

#map {
  height: 100%;
  width: 100%; }

.location-form {
  position: relative; }
  .location-form__button {
    border: 1px solid #003c71;
    border-radius: 0 20px 20px 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: 0.25s; }
    .location-form__button:hover, .location-form__button:focus {
      background: white;
      color: #003c71; }

.location-amenities h2 {
  color: #52446F; }

.location-amenities__featured {
  margin-bottom: 3rem; }
  .location-amenities__featured-item {
    background: #E7E7E8;
    border-radius: .5rem;
    color: #403158;
    font-size: .85rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: .75rem;
    padding: .75rem .5rem; }
    .location-amenities__featured-item .amenity-icon {
      margin-right: 1rem;
      min-width: 1.75rem;
      width: 1.75rem; }
      .location-amenities__featured-item .amenity-icon svg path,
      .location-amenities__featured-item .amenity-icon svg rectangle,
      .location-amenities__featured-item .amenity-icon svg stroke {
        fill: #BF8E40; }
      .location-amenities__featured-item .amenity-icon.privateSuitesForRehabilitation {
        min-width: 2.25rem; }

.location-amenities__main-item {
  margin-bottom: 1rem; }
  .location-amenities__main-item .amenity-icon {
    margin-right: 1rem;
    width: 1.5rem; }

.location-amenities__left {
  margin-bottom: 3rem; }

.location-amenities__gallery-main {
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  padding-bottom: 65%;
  position: relative; }
  .location-amenities__gallery-main img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .location-amenities__gallery-main img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.location-amenities__gallery-thumbs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
  grid-template-columns: repeat(4, 1fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  gap: 1rem; }
  .location-amenities__gallery-thumbs button {
    border: none; }

.location-amenities__gallery-thumb {
  padding-bottom: 90%;
  position: relative;
  width: 100%; }
  .location-amenities__gallery-thumb img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .location-amenities__gallery-thumb img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

@media screen and (min-width: 48em) {
  .location-amenities__main {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    column-gap: 2rem; }
  .location-amenities__left {
    padding-right: 1.5rem;
    margin-bottom: 0;
    width: 50%; }
  .location-amenities__gallery {
    padding-left: 1.5rem;
    width: 50%; } }

@media screen and (min-width: 62em) {
  .location-amenities__featured-item {
    margin-bottom: 0;
    margin-right: .5rem;
    width: calc(33.33% - .5rem); }
    .location-amenities__featured-item:last-child {
      margin-right: 0; } }

@media screen and (max-width: 61.999em) {
  .find-location-header .d-flex,
  .location-lists__card {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .location-header__content {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding-right: 0;
    padding-top: 1rem; }
  .location-header__button {
    border-radius: 30px;
    margin-left: -15%; }
  .location-header__input input,
  .location-header__input select {
    border-radius: 30px;
    padding-right: 16% !important; }
  .location-header__map {
    height: 0;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative; }
    .location-header__map #map {
      height: 100%;
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%; }
  .location-lists-card-content__lists,
  .location-lists-card__left figure,
  .location-lists-card__right .location-header__button,
  .help-layout {
    display: none; }
  .location-lists-card__left h4 {
    font-size: 17px; }
  .location-lists-card__right {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%; }
    .location-lists-card__right p {
      font-size: 1rem;
      margin-bottom: 18px; } }

.page-header.location-single {
  background-color: unset; }
  .page-header.location-single .contain {
    position: relative; }
  .page-header.location-single .page-header__content:before {
    content: none; }
  .page-header.location-single .page-header__text {
    margin-top: 3rem;
    padding-top: 0;
    position: relative; }
    .page-header.location-single .page-header__text p {
      margin-bottom: 1.5rem; }
      .page-header.location-single .page-header__text p > span {
        vertical-align: middle; }
      .page-header.location-single .page-header__text p > svg {
        margin-right: 0.75rem;
        vertical-align: middle; }
        .page-header.location-single .page-header__text p > svg.location {
          width: 1.75rem; }
        .page-header.location-single .page-header__text p > svg.phone {
          width: 1.5rem; }
  .page-header.location-single::before {
    background: linear-gradient(#020202, #27282A);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

@media screen and (min-width: 48em) {
  .page-header.location-single.dots .page-header__image {
    position: relative; }
    .page-header.location-single.dots .page-header__image:after {
      background-image: url("/images/dots2.svg");
      background-repeat: no-repeat;
      content: '';
      height: 25.1rem;
      left: -6.1rem;
      position: absolute;
      bottom: 0;
      width: 6.1rem;
      z-index: 2; }
  .page-header.location-single .page-header__content {
    padding-bottom: 6rem;
    padding-right: 6rem; }
  .page-header.location-single .page-header__foreground-image {
    height: auto;
    left: 45%;
    max-width: 15rem;
    position: absolute;
    top: 10%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2; }
  .page-header.location-single .page-header__text {
    padding-left: 1.5rem;
    position: relative; }
    .page-header.location-single .page-header__text:before {
      background: linear-gradient(54deg, rgba(16, 16, 16, 0) 16.96%, rgba(25, 26, 28, 0.66) 66.97%), linear-gradient(8deg, #EFD683 17.22%, #7E5B2D 37.05%);
      content: '';
      height: 100%;
      left: 3px;
      position: absolute;
      top: 0;
      width: 2px; }
    .page-header.location-single .page-header__text:after {
      background: url("/icons/triangle.svg") center no-repeat;
      bottom: -.9rem;
      content: '';
      height: .5rem;
      left: 0;
      position: absolute;
      width: .5rem; } }

@media screen and (min-width: 62em) {
  .page-header.location-single .page-header__foreground-image {
    left: 48%;
    max-width: 19rem; } }

.two-columns.location-single {
  background: linear-gradient(#27282A, #020202); }
  .two-columns.location-single .two-columns__right .link-list a {
    font-weight: 400;
    padding: 0 !important; }
    .two-columns.location-single .two-columns__right .link-list a:after {
      content: none; }
    .two-columns.location-single .two-columns__right .link-list a:hover {
      color: #EFD683; }
  @media screen and (min-width: 48em) {
    .two-columns.location-single .link-list.three-columns {
      columns: auto;
      column-gap: 0;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
      -ms-grid-rows: auto;
      grid-template-rows: auto;
      column-gap: 1rem; }
    .two-columns.location-single .two-columns__left {
      width: calc(45% - 3rem); }
    .two-columns.location-single .two-columns__right {
      width: 55%; } }
  @media screen and (min-width: 62em) {
    .two-columns.location-single .two-columns__left > .container {
      padding: 3rem; } }

.two-columns.location-body .contain {
  position: relative; }
  .two-columns.location-body .contain:after {
    background: url("/images/location-body-accent.svg") no-repeat top;
    background-size: contain;
    content: '';
    height: 100%;
    opacity: .25;
    width: 6rem;
    position: absolute;
    top: 0;
    right: -3rem;
    z-index: 1; }

.two-columns.location-body .two-columns__left {
  position: relative;
  z-index: 2; }

.two-columns.location-body .two-columns__right {
  display: none; }

@media screen and (min-width: 48em) {
  .two-columns.location-body .contain::after {
    opacity: 1;
    right: 3rem; }
  .two-columns.location-body .two-columns__left {
    padding: 1.5rem 0;
    width: 60%; }
    .two-columns.location-body .two-columns__left p {
      font-size: 1.125rem;
      line-height: 1.75; } }

.reviews__left {
  margin-bottom: 3rem; }

.reviews__right {
  padding-bottom: 4.5rem; }

.reviews__slide {
  background: #ffffff;
  border-top: 0.5rem solid #BF8E40;
  margin-right: 1.5rem;
  padding: 1.5rem; }

.reviews__quote, .reviews__date {
  color: #020202 !important; }

.reviews__rating {
  background: url("/icons/google.png") no-repeat left;
  background-size: 2.5rem;
  height: 2.5rem;
  padding-left: 3.5rem;
  margin-bottom: 1.5rem; }
  .reviews__rating-star {
    margin-right: .75rem;
    width: 1.25rem; }
    .reviews__rating-star:last-child {
      margin-right: 0; }
  .reviews__rating.stars-1 .reviews__rating-star:nth-child(5), .reviews__rating.stars-1 .reviews__rating-star:nth-child(4), .reviews__rating.stars-1 .reviews__rating-star:nth-child(3), .reviews__rating.stars-1 .reviews__rating-star:nth-child(2) {
    display: none; }
  .reviews__rating.stars-2 .reviews__rating-star:nth-child(5), .reviews__rating.stars-2 .reviews__rating-star:nth-child(4), .reviews__rating.stars-2 .reviews__rating-star:nth-child(3) {
    display: none; }
  .reviews__rating.stars-3 .reviews__rating-star:nth-child(5), .reviews__rating.stars-3 .reviews__rating-star:nth-child(4) {
    display: none; }
  .reviews__rating.stars-4 .reviews__rating-star:nth-child(5) {
    display: none; }

.reviews .flickity-viewport:focus, .reviews .flickity-viewport:active,
.reviews .flickity-slider:focus,
.reviews .flickity-slider:active,
.reviews .flickity-enabled:focus,
.reviews .flickity-enabled:active {
  box-shadow: none !important; }

.reviews .flickity-button {
  bottom: -1.5rem;
  top: auto; }
  .reviews .flickity-button.previous {
    left: auto;
    right: 4rem; }
  .reviews .flickity-button.next {
    right: 0; }

@media screen and (min-width: 48em) {
  .reviews__left {
    border-right: 1px solid #dadada;
    margin-bottom: 0;
    margin-right: 3rem;
    padding-right: 3rem;
    position: relative;
    z-index: 5;
    width: 40%; }
  .reviews__right {
    position: relative;
    width: 60%; }
    .reviews__right::before {
      background: #52446F;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      right: 100%;
      width: 100vw;
      z-index: 1; }
  .reviews__slide {
    width: 50%; }
  .reviews .flickity-viewport {
    overflow: visible; } }

/**
 * Sitemap page styles
 * DBS>Interactive
 */
.sitemap ul li {
  list-style-type: none;
  padding-left: 2.5rem;
  position: relative; }
  .sitemap ul li a {
    font-weight: 400;
    text-decoration: none; }
  .sitemap ul li:before {
    background: url("/icons/list-marker-gold.svg") no-repeat;
    content: '';
    height: 5px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25rem; }
  .sitemap ul li:not(:last-child) {
    margin-bottom: 1.5rem; }

.sitemap ul:not(:last-child) {
  margin-bottom: 3rem; }

.sitemap.page-header:not(.omnia-hcg) .page-header__content {
  padding-bottom: 3rem; }

@media screen and (max-width: 47.999em) {
  .sitemap .h5 {
    font-size: 1.5rem; } }
