/**
 * Slider ACF Layout Styles
 * DBS>Interactive
 */

.slider {

    &__cell {
        width: 100%; // Set number of visible slides by width percentage. This is one slide.
    }

    &__element {box-shadow: none !important;}

    .flickity-viewport {transition: height 0.25s;}

    // Nav buttons
    .flickity-button {

    }

    .flickity-page-dots {
        display: flex;
            align-items: center;
            justify-content: center;
        position: relative;
            bottom: 0;
        
    }

    // white circle styles for the dots
    button.dot {
        border-radius: 100%;
        width: .75em;
        height: .75em;
        padding: 0;
        margin: 0em .75em;
        opacity: 1;
        background: transparent;
        transform: scale(1);
        border: .1em solid $white;

        &:focus, &:hover,
        &.is-selected {
            background-color: $black;
            border-color: $white;
            transform: scale(1.5);
            transition: transform .25s linear;
        }
    }

    @include media($screen-sm) {
        &__cell {
            margin: 0 #{$spacing/2};
            width: calc(50% - #{$spacing});
        }
    }
}