.find-location-header {
    margin-top: 0;
    
    .breadcrumbs {
        margin-bottom: #{$spacing / 2};
        margin-top: #{$spacing / 2};
    }
    
    .d-flex {
        display: flex;
        gap: 20px;
    }
}

.location-header {
    &__content {
        flex-basis: 39.3%;
        padding-right: 4rem;
        padding-top: 5rem;
        
        #location-form {
            .location-form__container {
                input:-internal-autofill-selected,
                select:-internal-autofill-selected {
                    background-color: black;
                }
            }
            
            .location-form__form {
                width: auto;
            }
            
            #filter-service,
            #pac-input {
                border-radius: 0;
                color: $grayDarkest;
                font-size: 1rem;
                margin: 0;
                transition: border-radius .5s ease, width .5s ease;
                
                &::placeholder {
                    color: $grayDark;
                }
            }
        }
        
        .eyebrow {
            color: $gold;
            padding-left: 2rem;
            position: relative;
            text-transform: capitalize;
            
            &:before {
                background: url('/icons/locations.svg') no-repeat;
                content: '';
                height: 1.25rem;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1.25rem;
            }
        }
    }
    
    &__map {
        flex-basis: 59%;
        height: 491px;

        img {
            height: 100%;
        }
    }    
    
    &__input {
        display: flex;
        align-items: center;
        margin-bottom: $spacing;

        input,
        select {
            border-radius: 20px;
            border: 1px solid #9C9C9C !important;
            color: #6B6B6B;
            font-size: 14px;
            font-weight: 400;
        }
        
        select {
            appearance: none;
            line-height: 1.15;
            outline-offset: -2px;
            padding: .666em;
            text-indent: 1px;
            text-overflow: '';
            width: 100%;
        }
    }
    
    
    &__button {
        background-color: #63656A;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        margin-left: -5px;
        text-decoration: none;

        &:hover {
            color: #ffffff;
            background-color: #2d2d2d;
        }
    }
    
    
    &__bottom {
        margin-top: 2.5rem;
        display: flex;
        gap: 20px;
    }
    
    &-bottom {        
        &__content {
            display: flex;
            gap: 10px;
            flex-basis: 50%;
            align-items: center;

            figure {
                width: 2rem;
                height: 2rem;
                margin: 0;
            }
            
            h4 {
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                text-transform: capitalize;
                color: $grayDarkest;
                margin-bottom: 0;
                width: calc(100% - 45px);
            }
        }
    }
}

.location-lists {
    margin-top: #{$spacing * 3};
    
    &__card {
        display: flex;
        gap: $spacing;
        border-top: 1px solid #D9D9D9;
        padding-top: $spacing;
        padding-left: $spacing;
        padding-right: $spacing;
        margin-bottom: $spacing;
    }
    
    &-card {
        &__left {
            display: flex;
            flex-basis: 75%;
            gap: 2.5rem;
            
            figure {
                margin: 0;
            }
            
            h4 {
                color: #1A2E50;
                font-size: 28px;
                font-weight: 400;
                line-height: normal;
            }
        }
        
        &__right {
            flex-basis: 23.3%;
            
            p {
                margin-bottom: $spacing;
                
                img {
                    float: left;
                    margin-right: 8px;
                }
                
                &:first-child img {
                    margin-left: -7px;
                }

                >span {
                    vertical-align: middle;
                }
                
                >svg {
                    vertical-align: middle;
                    
                    &.location {
                        width: 1.75rem;
                    }
                    
                    &.phone {
                        width: 1.5rem;
                    }
                }
            }
            
            
            .location-header__button {
                margin-left: 0;
                width: 80%;
                display: block;
                text-align: center;
                margin-top: 40px;
            }
        }
        
        &-content {
            h4 {
                font-weight: 700;
            }
            
            &__lists {
                margin-bottom: 25px;

                li {
                    font-family: Helvetica;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    color: #1A2E50;
                    margin-bottom: 10px;
                    position: relative;
                    list-style: none;

                    &::before {
                        content: "";
                        position: absolute;
                        left: -27px;
                        top: 10px;
                        width: 1rem;
                        height: 3px;
                        background: #4E77B9;
                    }
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    
    @include media($screen-sm) {
        &__card {
            display: flex;
        }
        
        &-card {
            &__left {
                width: 80%;
                
                img {
                    max-width: 18rem;
                }
            }
            
            &__right {
                width: 20%;
            }
        }
    }
    
    @include media($screen-sm-max, 'max') {
        &-card__left {
            flex-basis: auto;
            flex-direction: column;
            gap: $spacing;
        }
    }
}


$base-font-size: 16px; // Assuming 1rem = 16px

.help-layout {
    padding: 2.25rem 0 3.75rem; // 36px, 0, 60px
}

.help__content {
    text-align: center;
    padding: 0 3.125rem; // 0, 50px

    h4 {
        color: #505D68;
        text-align: center;
        font-size: 1.75rem; // 28px
        font-style: normal;
        font-weight: 400;
        line-height: 2.375rem; // 38px
        text-transform: uppercase;
        margin-bottom: 1.4375rem; // 23px
    }

    p {
        color: #505D68;
        text-align: center;
        font-size: 1.625rem; // 26px
        font-style: normal;
        font-weight: 300;
        line-height: 2.1875rem; // 35px
        margin-bottom: 1.4375rem; // 23px
    }

    .location-header__button {
        margin-left: 0;
        display: inline-block;
        padding: 0.625rem 2.8125rem; // 10px, 45px
    }
}


#map {
    height: 100%;
    width: 100%;
}

.location-form {
    position: relative;

    &__button {
        border: 1px solid #003c71;
        border-radius: 0 20px 20px 0;
        padding-left: $spacing;
        padding-right: $spacing;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: 0.25s;

        &:hover,
        &:focus {
            background: white;
            color: #003c71;
        }
    }
}

.location-amenities {
    h2 {color: #52446F;}

    &__featured {
        margin-bottom: 3rem;

        &-item {
            background: #E7E7E8;
            border-radius: .5rem;
            color: #403158;
            font-size: .85rem;
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: .75rem;
            padding: .75rem .5rem;

            .amenity-icon {
                margin-right: 1rem;
                min-width: 1.75rem;
                width: 1.75rem;

                svg {
                    path,
                    rectangle,
                    stroke {
                        fill: #BF8E40;
                    }
                }
                
                &.privateSuitesForRehabilitation {
                    min-width: 2.25rem;
                }
            }
        }
    }

    &__main {

        &-item {
            margin-bottom: 1rem;

            .amenity-icon {
                margin-right: 1rem;
                width: 1.5rem;
            }
        }
    }

    &__left {
        margin-bottom: 3rem;
    }

    &__gallery {
        
        &-main {
            height: 0;
            margin-bottom: 1rem;
            overflow: hidden;
            padding-bottom: 65%;
            position: relative;

            img { @include object-fit(cover, center); }
        }

        &-thumbs {
            display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: auto;
            gap: 1rem;

            button {
                border: none;
            }
        }

        &-thumb {
            padding-bottom: 90%;
            position: relative;
            width: 100%;

            img { @include object-fit(cover, center); }
        }
    }

    @include media($screen-sm) {

        &__main {
            display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
                column-gap: 2rem;
        }

        &__left {
            padding-right: $spacing;
            margin-bottom: 0;
            width: 50%;
        }

        &__gallery {
            padding-left: $spacing;
            width: 50%;
        }
    }

    @include media($screen-md) {
        &__featured {

            &-item {
                margin-bottom: 0;
                margin-right: .5rem;
                width: calc(33.33% - .5rem);

                &:last-child {margin-right: 0;}
            }
        }
    }
}

@include media($screen-md-max, 'max') {

    .find-location-header .d-flex,
    .location-lists__card {
        flex-wrap: wrap;
    }

    .location-header {
        &__content {
            flex-basis: 100%;
            padding-right: 0;
            padding-top: 1rem;
        }
        
        &__button {
            border-radius: 30px;
            margin-left: -15%;
        }
        
        &__input {
            input,
            select {
                border-radius: 30px;
                padding-right: 16% !important;
            }
        }
        
        &__map {
            height: 0;
            flex-basis: 100%;
            overflow: hidden;
            padding-bottom: 75%;
            position: relative;
            
            #map {
                height: 100%;
                position: absolute !important;
                top: 0;
                left: 0;
                width: 100%;
            }
        }   
    }

    .location-lists-card-content__lists,
    .location-lists-card__left figure,
    .location-lists-card__right .location-header__button,
    .help-layout {
        display: none;
    }

    .location-lists-card {
        &__left h4 {
            font-size: 17px;
        }
        
        &__right {
            flex-basis: 100%;
            
            p {
                font-size: 1rem;
                margin-bottom: 18px;
            }
        }
        
    }
}

.page-header {
    &.location-single {
        background-color: unset;
        
        .contain {
            position: relative;
        }
        
        .page-header__content {
            &:before {
                content: none;
            }
        }
        
        // .page-header__foreground-image {
        //     height: auto;
        //     position: absolute;
        //     right: 0;
        //     top: 0;
        //     width: 50%;
        // }
        
        .page-header__text {
            margin-top: #{$spacing * 2};
            padding-top: 0;
            position: relative;
            
            p {
                margin-bottom: $spacing;
                
                >span {
                    vertical-align: middle;
                }
                
                >svg {
                    margin-right: #{$spacing / 2};
                    vertical-align: middle;
                    
                    &.location {
                        width: 1.75rem;
                    }
                    
                    &.phone {
                        width: 1.5rem;
                    }
                }
            }
        }
        
        &::before {
            background: linear-gradient($black, $grayDarkest);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    
    @include media($screen-sm) {
        &.location-single {
            &.dots {
                .page-header__image {
                    position: relative;
                                
                    &:after {
                        background-image: url('/images/dots2.svg');
                        background-repeat: no-repeat;
                        content: '';
                        height: 25.1rem;
                        left: -6.1rem;
                        position: absolute;
                        bottom: 0;
                        width: 6.1rem;
                        z-index: 2;
                    }
                }
            }
            
            .page-header__content {
                padding-bottom: #{$spacing * 4};
                padding-right: #{$spacing * 4};
            }
            
            .page-header__foreground-image {
                height: auto;
                left: 45%;
                max-width: 15rem;
                position: absolute;
                top: 10%;
                transform: translateX(-50%);
                width: 100%;
                z-index: 2;
            }
            
            .page-header__text {
                padding-left: $spacing;
                position: relative;
                
                &:before {
                    background: linear-gradient(54deg, rgba(16, 16, 16, 0.00) 16.96%, rgba(25, 26, 28, 0.66) 66.97%), linear-gradient(8deg, #EFD683 17.22%, #7E5B2D 37.05%);
                    content: '';
                    height: 100%;
                    left: 3px;
                    position: absolute;
                    top: 0;
                    width: 2px;
                }
                
                &:after {
                    background: url('/icons/triangle.svg') center no-repeat;
                    bottom: -.9rem;
                    content: '';
                    height: .5rem;
                    left: 0;
                    position: absolute;
                    width: .5rem;
                }
            }
        }
    }
    
    @include media($screen-md) {
        &.location-single {
            .page-header__foreground-image {
                left: 48%;
                max-width: 19rem;
            }
        }
    }
}

.two-columns.location-single {
    background: linear-gradient($grayDarkest, $black);
    
    .two-columns__right {
        .link-list {
            a {
                font-weight: 400;
                padding: 0 !important;
                
                &:after {
                    content: none;
                }
                
                &:hover {
                    color: $yellow;
                }
            }
        }
    }

    @include media($screen-sm) {
        .link-list.three-columns {
            columns: auto;
            column-gap: 0;
            display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: auto;
                column-gap: 1rem;
        }

        .two-columns__left {
            width: calc(45% - 3rem);
        }

        .two-columns__right {
            width: 55%;
        }
    }

    @include media($screen-md) {
        .two-columns__left>.container {
            padding: #{$spacing * 2};
        }
    }
}

.two-columns.location-body {

    .contain {
        position: relative;

        &:after {
            background: url('/images/location-body-accent.svg') no-repeat top;
                background-size: contain;
            content: '';
            height: 100%;
            opacity: .25;
            width: 6rem;
            position: absolute;
                top: 0;
                right: -3rem;
            z-index: 1;
        }
    }
        
    .two-columns__left {
        position: relative;
        z-index: 2;
    }

    .two-columns__right {display: none;}

    @include media($screen-sm) {

        .contain::after {
            opacity: 1;
            right: 3rem;
        }

        .two-columns__left {
            padding: 1.5rem 0;
            width: 60%;

            p {
                font-size: 1.125rem;
                line-height: 1.75;
            }
        }
    }
}


.reviews {

    &__left {
        margin-bottom: 3rem;
    }

    &__right {
        padding-bottom: 4.5rem;
    }

    &__slide {
        background: $white;
        border-top: .5rem solid $gold;
        margin-right: $spacing;
        padding: $spacing;
    }

    &__quote,
    &__date {
        color: $black !important;
    }

    &__rating {
        background: url('/icons/google.png') no-repeat left;
            background-size: 2.5rem;
        height: 2.5rem;
        padding-left: 3.5rem;
        margin-bottom: $spacing;

        &-star {
            margin-right: .75rem;
            width: 1.25rem;

            &:last-child {margin-right: 0;}
        }

        &.stars-1 {
            .reviews__rating-star {
                &:nth-child(5),
                &:nth-child(4),
                &:nth-child(3),
                &:nth-child(2) {display: none;}
            }
        }

        &.stars-2 {
            .reviews__rating-star {
                &:nth-child(5),
                &:nth-child(4),
                &:nth-child(3) {display: none;}
            }
        }

        &.stars-3 {
            .reviews__rating-star {
                &:nth-child(5),
                &:nth-child(4) {display: none;}
            }
        }

        &.stars-4 {
            .reviews__rating-star {
                &:nth-child(5) {display: none;}
            }
        }
    }

    .flickity-viewport,
    .flickity-slider,
    .flickity-enabled {
        &:focus,
        &:active {box-shadow: none !important;}
    }

    .flickity-button {
        bottom: -1.5rem;
        top: auto;

        &.previous {
            left: auto;
            right: 4rem;
        }

        &.next {
            right: 0;
        }
    }

    @include media($screen-sm) {

        &__left {
            border-right: 1px solid #dadada;
            margin-bottom: 0;
            margin-right: 3rem;
            padding-right: 3rem;
            position: relative;
            z-index: 5;
            width: 40%;
        }

        &__right {
            position: relative;
            width: 60%;

            &::before {
                background: $purple;
                content: '';
                height: 100%;
                position: absolute;
                    top: 0;
                    right: 100%;
                width: 100vw;
                z-index: 1;
            }
        }

        &__slide {
            width: 50%;
        }

        .flickity-viewport {overflow: visible;}
    }
}