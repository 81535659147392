/**
 * Sitemap page styles
 * DBS>Interactive
 */

.sitemap {
    ul {        
        li {
            list-style-type: none;
            padding-left: 2.5rem;
            position: relative;
            
            a {
                font-weight: 400;
                text-decoration: none;
            }
            
            &:before {
                background: url('/icons/list-marker-gold.svg') no-repeat;
                content: '';
                height: 5px;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1.25rem;
            }
            
            &:not(:last-child) {
                margin-bottom: $spacing;
            }
        }
        
        &:not(:last-child) {
            margin-bottom: #{$spacing * 2};
        }
    }
    
    &.page-header:not(.omnia-hcg) {
        .page-header__content {
            padding-bottom: #{$spacing * 2};
        }
    }
    
    @include media($screen-sm-max, 'max') {
        .h5 {
            font-size: 1.5rem;
        }
    }
}
