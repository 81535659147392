/**
 * Collapsible Layout
 * DBS>Interactive
 */

.collapsible {
	@include default-margin;
    background-color: $whiteDark;
    padding: #{$spacing * 2} 0;
    
    hr {
        border-top: 2px solid $gold;
        margin: $spacing 0;
        width: 5rem;
    }

	.collapsible-row {
		overflow: hidden;
		border-top: 1px solid $white;

		&:first-of-type {border: none;}

		// Row Header
		&__header {
			@include font-scale(1);
            align-items: center;
			background: $white;
            border: 0;
            border-bottom: 1px solid $whiteDark !important;
			color: $textColor;
			cursor: pointer;
			display: flex;
            font-weight: 600;
			margin-bottom: 0;
			padding: 1rem 4rem 1rem 2rem;
			position: relative;
			text-align: left;
			transition: .4s;
			width: 100%;
            
            img {
                margin-right: 1rem;
            }

			&:hover,
			&:focus {
				background: $yellowLight;
			}

			&:after {
                background: url('/icons/circle-arrow.svg');
				content: '';
				display: block;
				height: 2rem;
				position: absolute;
					top: 50%;
					right: 0;
				transition: transform .65s;
				transform: translate(-100%, -50%);
				width: 1.9rem;
			}

			@at-root .collapsible .expanded .collapsible-row__header {
				background: $yellowLight;

				&:after {transform: translate(-100%, -50%) rotate(90deg);}
			}
            
            &.active {
                &:after {transform: translate(-100%, -50%) rotate(180deg);}
            }
		}

		// Row Content
		&__content {
            background-color: $white;
			padding: 2rem;

			@include media($screen-sm) {
				padding: 2rem 2rem 3rem;
			}
		}

		&__content--wrapper {
            height: 100%;
            max-height: 0px;
			overflow: hidden;
			transition: max-height .4s ease-in-out, visibility 0s linear .5s;

			&.loaded { // Ensures that content is visible if JS doesn't load
				max-height: 0;
			}

			@at-root .collapsible .expanded .collapsible-row__content--wrapper {
				max-height: 15em; // May need to be adjusted based on content height
				transition: max-height .4s ease-in-out, visibility 0s linear;
			}
		}
	}
    
    .contain {
        display: flex;
        flex-wrap: wrap;
        gap: #{$spacing / 4};
    }
    
    &__heading {
        margin-bottom: #{$spacing * 2};
    }
    
    @include media($screen-sm) {
        .collapsible-row {
            width: calc(50% - #{$spacing / 8});
        }
        
        &__heading {
            width: 65%;
        }
    }
}
