/**
 * List w/ Background Flex Layout
 * DBS>Interactive
 */

.list-with-background {
    position: relative;
    
    hr {
        border-top: 2px solid $gold;
        width: 5rem;
    }
    
    &__content {
        padding-left: #{$spacing * 5};
        position: relative;
        z-index: 10;
        
        &.right {
            float: none;
            
            .list-with-background__heading {
                a {
                    margin-left: 0;
                }
            }
            
            .main-heading {
                display: block;
            }
        }
    }
    
    &__heading {
        margin-bottom: $spacing;
        
        a {
            margin-left: #{$spacing * 2};
        }

        .eyebrow {
            color: $gold;
            padding-left: 2rem;
            position: relative;
            text-transform: capitalize;
            
            &:before {
                background: url('/icons/locations.svg') no-repeat;
                content: '';
                height: 1.25rem;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1.25rem;
            }
        }
        
        .main-heading {
            display: inline-block;
        }
    }
    
    &__list {
        font-size: 1.125rem;
        margin: 0;
        padding-left: 0;
        position: relative;
        z-index: 10;
        
        li {
            list-style-type: none;
            padding-left: 2.5rem;
            position: relative;
            
            a {
                font-weight: 400;
                text-decoration: none;
            }
            
            &:before {
                background: url('/icons/list-marker-gold.svg') no-repeat;
                content: '';
                height: 5px;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1.25rem;
            }
            
            &:not(:last-child) {
                margin-bottom: $spacing;
            }
        }
    }
    
    .image-wrapper {
        height: 100%;
        position: absolute;
        top: 0;
        
        img {
            height: 100%;
            max-width: none;
            object-fit: cover;
            object-position: left;
            width: 100vw;
        }
        
        &.gold-gradient {
            &::before {
                background: linear-gradient(90deg, rgba(4, 4, 4, 0.00) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
                content: '';
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
            
            &::after {
                background: linear-gradient(0deg, #000 0%, #000 100%), linear-gradient(90deg, rgba(4, 4, 4, 0.00) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
                mix-blend-mode: color;
                content: '';
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
            
            .overlays-one,
            .overlays-two {
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
            }
            
            .overlays-one {
                &::before {
                    background: linear-gradient(95deg, rgba(126, 91, 45, 0.44) 10.94%, rgba(239, 214, 131, 0.44) 85.89%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0.00) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
                    mix-blend-mode: overlay;
                    content: '';
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 2;
                }
                
                &::after {
                    opacity: 0.76;
                    background: linear-gradient(99deg, #7E5B2D 20.58%, #EFD683 88.84%), linear-gradient(0deg, #EFD683 0%, #EFD683 100%), linear-gradient(90deg, rgba(4, 4, 4, 0.00) 15.59%, rgba(0, 0, 0, 0.51) 51.63%);
                    mix-blend-mode: overlay;
                    content: '';
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 3;
                }
            }
            
            .overlays-two {                
                &::before {
                    background: linear-gradient(270deg, rgba(4, 4, 4, 0.04) 1.84%, #37260F 75.94%);
                    content: '';
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 4;
                }
                
                &::after {
                    opacity: 0.71;
                    background: linear-gradient(70deg, rgba(4, 4, 4, 0.04) 35.45%, #37260F 63.18%);
                    content: '';
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 5;
                }
            }
        }
        
        &.dark-gray-gradient {
            &::after {
                background: linear-gradient(180deg, $blackLight 0%, transparent 20%, transparent 100%);
                mix-blend-mode: multiply;
                content: '';
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
        
        &.brown-filter {
            &::after {                
                background: rgba(55, 39, 15,0.8);
                mix-blend-mode: multiply;
                content: '';
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }
    
    @include media($screen-sm) {
        &.bg-dark.layout {            
            padding-bottom: #{$spacing * 4};
            padding-top: #{$spacing * 4};
        }
        
        &.dots {
            .list-with-background__content::before {
                background-image: url('/images/dots2.svg');
                background-repeat: no-repeat;
                bottom: -8.7rem;
                content: '';
                height: 100%;
                left: -5rem;
                position: absolute;
                width: 6.1rem;
                z-index: 6;
            }
        }
        
        &__content {
            &.right {
                display: flex;
                float: none;
                gap: #{$spacing * 2};
                
                .list-with-background__heading {
                    order: 1;
                    
                    a {
                        margin-left: 0;
                    }
                }
                
                .list-with-background__list {
                    columns: 2;
                }
            }
        }
        
        &__list {
            columns: 2;
        }
    }
    
    @include media($screen-md) {
        &__list {
            columns: 3;
        }
    }
    
    @include media($screen-sm-max, 'max') {
        &__content {
            padding: 0;
        }
        
        &__heading {
            margin-bottom: #{$spacing * 2};
            
            a {
                margin-left: 0;
            }
            
            .main-heading {
                display: block;
            }
        }
    }
}
