.brand-strip {
    background-color: $black;
    content-visibility: auto;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: #{$spacing * 2};
    padding-top: #{$spacing * 2};

    &__heading,
    &__subheading {
        max-width: 47rem;
        text-align: center;

        p {
            font-size: 1.125rem;

            @include media ($screen-sm) {
                font-size: 1.25rem;
            }
        }
    }

    &__heading {
        margin: 0 auto #{$spacing * 2};
    }

    &__subheading {
        margin: #{$spacing * 2} auto 0;
    }

    &__strip {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        // Mobile styles
        @include media ($screen-md-max, 'max') {
            flex-wrap: wrap;
        }
    }

    &__image {
        padding: 1rem;

        img {
            max-height: 50px;
            width: auto;
        }

        @include media ($screen-sm) {
            img {
                max-height: 75px;
            }
        }

        @include media ($screen-md) {
            img {
                max-height: 100px;
            }
        }

    }
}