/**
 * Page Header Flex Layout
 * DBS>Interactive
 */

.page-header {
	@include vertical-padding;
    background-color: $grayDarkest;
	margin-top: 0 !important;
    overflow: hidden;
	position: relative;
    
    hr {
        border-top: 2px solid $gold;
        margin: $spacing 0;
        width: 5rem;
    }

	&__bg-image {
		@include size(100%);
		position: absolute;
			top: 0;
			left: 0;
		z-index: 1;

		img {@include object-fit(cover, center);}

		&:before {
            @include size(100%);
            background: linear-gradient(180deg, #27292B 0%, #000 100%), #D9D9D9;
			content: '';
            left: 0;
            mix-blend-mode: color;
			position: absolute;
            top: 0;
            z-index: 1;
		}
		
        &:after {
            @include size(100%);
            background: radial-gradient(82.01% 51.74% at 76.49% 31.22%, rgba(0, 0, 0, 0.00) 0%, #27292B 100%), #D9D9D9;
			content: '';
            left: 0;
            mix-blend-mode: multiply;
			position: absolute;
            top: 0;
            z-index: 2;
		}
        
        .bg-overlay {
            height: 101%;
            position: relative;
            width: 100%;
            
            &:before {
                @include size(100%);
                background: linear-gradient(180deg, #252729 0%, rgba(39, 40, 42, 0.00) 100%);
                content: '';
                left: 0;
                position: absolute;
                top: 0;
                z-index: 1;
            }
            
            &:after {
                content: none;
            }
        }
	}

	&__content {
		position: relative;
		z-index: 2;

		h1:last-child {margin-bottom: 0;}
	}
    
    &.citadel-healthcare,
    &.pavilion-healthcare {
        background-color: $white;
        padding-bottom: 0;
        padding-top: 0;
        
        .contain {
            align-items: flex-start;
        }
        
        .eyebrow {
            color: $orange;
            font-weight: 600;
            position: relative;
            text-transform: capitalize;
            
            &::before {
                background-color: $orange;
                content: '';
                height: 1px;
                position: absolute;
                right: calc(100% + #{$spacing / 2});
                top: 50%;
                transform: translateY(-50%);
                width: 1000%;
            }
        }
        
        .page-header__content {
            padding-top: #{$spacing * 3};
            
            &::before {
                background: linear-gradient(0deg, $black 0%, $grayDarkest 100%);
                content: '';
                height: calc(100% + #{$spacing * 3});
                left: -500%;
                position: absolute;
                top: 0;
                width: 1000%;
            }
        }
        
        .page-header__heading {
            position: relative;
            
            h1 {
                color: $white;
            }
        }
        
        .page-header__image {
            align-self: stretch;
            position: relative;
            z-index: 2;
            
            img {
                height: 100%;
                object-fit: cover;
            }
        }
        
        .page-header__text {
            padding-top: #{$spacing * 5};
        }
    }
    
    &.omnia-hcg {
        .page-header__heading {
            h1 {
                color: $yellow;
            }
        }
        
        .page-header__text {
            margin-top: #{$spacing * 2};
        }
    }
    
    .contain {
        display: flex;
            flex-direction: column;
            gap: #{$spacing * 2};
    }
    
    @include media($screen-sm) {
                
        &__content,
        &__image {
            width: 50%;
        }
        
        &.citadel-healthcare,
        &.pavilion-healthcare {
            .page-header__content-wrapper {
                width: calc(50% - #{$spacing * 2});
                
                .page-header__content {
                    width: auto;
                }
            }
        }
        
        &.omnia-hcg {            
            &.big-o {
                .page-header__image {
                    position: relative;
                                
                    &:before {
                        background-image: url('/images/big-o.svg');
                        background-size: cover;
                        background-repeat: no-repeat;
                        content: '';
                        height: 118%;
                        left: -3%;
                        position: absolute;
                        top: -10%;
                        width: 103%;
                        z-index: 2;
                    }
                }
            }

            &.dots {
                .page-header__image {
                    position: relative;
                                
                    &:after {
                        background-image: url('/images/dots1.svg');
                        background-repeat: no-repeat;
                        content: '';
                        height: 100%;
                        right: -75%;
                        opacity: .3;
                        position: absolute;
                        top: 50%;
                        width: 100%;
                        z-index: 2;
                    }
                }
            }
            
            .page-header__text {
                margin-left: 1.5rem;
                padding-left: 3rem;
                position: relative;
                
                &:before {
                    background: linear-gradient(54deg, rgba(16, 16, 16, 0.00) 16.96%, rgba(25, 26, 28, 0.66) 66.97%), linear-gradient(8deg, #EFD683 17.22%, #7E5B2D 37.05%);
                    content: '';
                    height: 100%;
                    left: 3px;
                    position: absolute;
                    top: 0;
                    width: 2px;
                }
                
                &:after {
                    background: url('/icons/triangle.svg') center no-repeat;
                    bottom: -.9rem;
                    content: '';
                    height: .5rem;
                    left: 0;
                    position: absolute;
                    width: .5rem;
                }
            }
        }
        
        .contain {
            flex-direction: row;
        }

        
    }
    
    @include media($screen-sm-max, 'max') {
        &__content {
            order: 1;
        }
    }
}
