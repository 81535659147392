.testimonials {
	&__slides {box-shadow: none !important;}

	&__wrapper {
		padding: $spacing;
		width: 100%;
	}

	&__author {
		border-bottom: 1px solid #D9D9D9;
		margin-bottom: 1.5rem;
		padding-bottom: 1rem;
	}

	&__body {
		padding-left: 1.5rem;
		position: relative;

		&::before {
			@include size(1rem);
			background: url('#{$libraryPath}/icons/quote.svg') no-repeat center;
				background-size: contain;
			content: '';
			font-size: 2rem;
			position: absolute;
				top: .25rem;
				left: 0;
		}
	}

	.flickity-button {
		&.previous {left: 0;}
		&.next {right: 0;}
	}

	@include media($screen-sm) {
		&__wrapper {
			padding: 3rem;
		}

		&__body {
			padding-left: 2.5rem;

			&:before {@include size(1.5rem);}
		}
	}
}