/**
 * Form Styles
 * DBS>Interactive
 */

.form {

	ul { list-style: none; padding: 0; }

	label, legend {
		font-weight: bold;
	}

	&__field {
		margin-bottom: 1em;
	}

	.error-text {
		border-bottom: 0.1em solid $secondaryColor;
		color: $secondaryColor;
		padding-bottom: 1em;
	}

	/**
	* Field Widths/Wrapping
	*/
	@include media($screen-sm) {
		
		display: flex;
			flex-wrap: wrap;
		margin: 0 -.75rem; // Offset field padding

		&__field-wrapper {
			padding: 0 .75rem;
			width: 100%;

			&.width-25 {width: 25%;}
			&.width-33 {width: 33.33%;}
			&.width-50 {width: 50%;}
			&.width-66 {width: 66.66%;}
			&.width-75 {width: 75%;}
		}

		.button {margin-left: .75rem;} // Align with fields
	}
}


fieldset,
.hidden_label,
.hide-label { 
	> label.gfield_label_before_complex,
    legend.gfield_label_before_complex {
        display: none;
    }

	> label, legend {
		@include size(1px);
		border    : 0;
		clip      : rect(0 0 0 0);
		margin    : -1px;
		overflow  : hidden;
		padding   : 0;
		position  : absolute;
	}
}


/**
 * Input Field Styles
 */

textarea {
	min-height: 5em;
}

input[type=radio] {
	margin-right: .5em;
}

select {
	min-width: 25%;
	margin: 0;
}

// Text and text-like input fields
textarea,
input[type=text]:not([id=search-input],[id=blog-search-field]),
input[type=email],
input[type=date],
input[type=number],
input[type=time],
input[type=week],
input[type=month],
input[type=tel],
input[type=search],
input[type=url],
.gfield select {
	display: block;
	border: 1px solid $yellow;
	max-width: 100%;
	padding: .666em;
	width: 100%;
}

fieldset {
    border: none;
	margin-bottom: $spacing*2;
    padding: 0 !important;
}

.freeform-form-container {
    font-family: unset !important;
    
    button {
        background-color: $gold;
        border-color: $gold;
        border-radius: 0;
        padding: .75rem 2rem;
        font: unset;
        font-weight: 700;
        
        &:focus {
            border-color: $goldDark;
        }
        
        &:hover {
            background-color: $goldDark;
            border-color: $goldDark;
        }
    }
    
    .freeform-row {
        font: unset;
        
    }
    
    .freeform-row [class*="freeform-col-"].floating input, .freeform-row [class*="freeform-col-"].floating .StripeElement, .freeform-row [class*="freeform-col-"].floating textarea, .freeform-row [class*="freeform-col-"].floating select {
        font: unset;
    }
    
    .freeform-row [class*="freeform-col-"].floating input:placeholder-shown:not(:focus) + *, .freeform-row [class*="freeform-col-"].floating textarea:placeholder-shown:not(:focus) + *, .freeform-row [class*="freeform-col-"].floating select:placeholder-shown:not(:focus) + * {
        font: unset;
    }
    
    .freeform-row [class*="freeform-col-"].floating label {
        font: unset;
    }
}

/**
 * Validation and error message styling
 */

.error-text {
	color: red;
}


/**
 * Animated Form Label
 *
 * Class must be added in the GForms admin
 */
.animate-label {
	position: relative;

	.ginput_complex {
		> span {
			@include size(100%);
			display: inline-block;
			position: relative;

			@at-root .gform_validation_error .animate-label,
			&.active {
				label, legend {transform: translateY(-2.25rem) scale(.75);}
			}
		}

		&.gfield_error {
			label {transform: translateY(-2.25rem) scale(.75);}
		}
	}

	label, legend {
		background: white;
		padding: 0 .25rem;
		position: absolute;
			top: 50%;
			left: .5rem;
		transition: .2s ease-in-out;
		transform: translateY(-50%);
		transform-origin: left;
	}

	&.select-field {
		select {
			font-weight: 700;
			padding: 1.5rem .75rem;
		}
	}

	&.text-area {
		label {
			top: .5rem;
			transform: none;
		}

		textarea {
			max-height: 12rem;
			padding-top: 3rem;
		}
        
        &.gfield_error label, &.gfield_error legend {transform: none;}
	}

	@at-root .gform_validation_error .animate-label,
	&.active {
		label, legend {transform: translateY(-2.25rem) scale(.75);}
	}
}