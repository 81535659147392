.contact-cta {
	&__wrapper {
		padding: $spacing;
		position: relative;
	}

	@include media($screen-sm) {
		&__wrapper {
			padding: $spacing*2;
		}
	}

	@include media($screen-md) {
		&__wrapper {
			padding: $spacing*3;
			padding-right: 9rem;
		}

		&__main {
			padding-right: 3rem;

			h3 {
				margin-bottom: 0;
				max-width: 34rem;
			}
		}

		.button.ghost {
			font-size: 1.125rem;
			padding: 1rem 3rem;
		}
	}
}